import React, { useState } from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconEnvelopeOpen from '@flowardco/fui-icons/src/IconEnvelopeOpen';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { CardMessageModal } from './CardMessageModal';
import { Snackbar } from '@flowardco/fui-snackbar';

export const CardMessageCard = ({
  orderCardData = {},
}: {
  orderCardData: any;
}) => {
  const { __T } = useLocalTranslator();
  const [showModal, setShowModal] = useState<boolean>(false);
  const messageText = orderCardData?.data?.messageText || '';
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className='fui-block fui-pb-2 fui-pt-3'>
      <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
        <span>
          <IconHeading
            title={__T('Card Message')}
            icon={<IconEnvelopeOpen />}
          />
        </span>
        <span>
          <button
            className='fui-text-sm fui-font-medium fui-text-theme-navy-900'
            type='button'
            onClick={toggleModal}
          >
            {__T(messageText ? 'Change' : 'Add')}
          </button>
        </span>
      </div>
      <div className='fui-min-h-[5rem] fui-rounded-md fui-border fui-border-gray-250 fui-p-3 fui-text-md fui-text-theme-neutral-500'>
        {messageText ||
          __T(
            'Write what you want to say and it will be attached to your flowers'
          )}
      </div>
      {showModal ? <CardMessageModal onCloseModal={toggleModal} /> : ''}
      {messageText ? (
        ''
      ) : (
        <Snackbar
          noteText={__T('There is no card message attached')}
          withIcon
          className='fui-mt-4 fui-flex'
        />
      )}
    </div>
  );
};
