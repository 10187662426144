import React from 'react';
import type { CartItemProps, CardMode } from './CartItem';
import { CartItem } from './CartItem';
import { SubCartItem } from './SubCartItem';
import { GiftWrapSection, type GiftWrapSectionProps } from './GiftWrapSection';
import IconWaningAlt from '@flowardco/fui-icons/src/IconWaningAlt';
import { CartValidationErrorEnum } from '@flowardco/flib-models';
import cx from 'clsx';
import { BasketItemSection } from './BasketItemSection';
export interface CartListProps extends GiftWrapSectionProps {
  className?: string;
  cartItems?: CartItemProps[];
  addonBtnLabel?: string;
  qtyLabel?: string;
  mode?: CardMode;
  cakeMessageLabel?: string;
  editButtonLabel?: string;
  onEditClick?(item: CartItemProps, parent?: CartItemProps): void;
  onAddClick?(item: CartItemProps): void;
  onDeleteClick?(item: CartItemProps, index: number): void;
  onIncrement?(item: CartItemProps, newVal: number): void;
  onDecrement?(item: CartItemProps, newVal: number): void;
  keySuffix?: string;
  notSameDayDeliveryMessage?: string;
  processingItems?: CartItemProps[];
  imageClass?: string;
  perViewSlides?: number;
  giftWrappingDisclaimerClass?: string;
  removeBtnLabel?: string;
  cartItemClassName?: string;
  basketItemWidgetTitle?: string;
}

export const CartList: React.FC<CartListProps> = ({
  className = '',
  cartItems = [],
  addonBtnLabel = 'Add',
  qtyLabel = 'Qty',
  mode = 'edit',
  cakeMessageLabel = 'Cake Message',
  editButtonLabel = 'Edit',
  onEditClick = () => null,
  onAddClick = () => null,
  onDeleteClick = () => null,
  onIncrement = () => null,
  onDecrement = () => null,
  keySuffix = '',
  notSameDayDeliveryMessage = 'This item is not available for same day delivery',
  processingItems = [],
  imageClass = '',
  showExpandedGiftList = 2,
  giftWrappingDisclaimer = 'Only gifts and non-flower products will be wrapped',
  giftWrapWidgetTitle = 'Gift wrapping',
  giftWrapList,
  handleOnSelectWrapClick = () => null,
  giftWrapBtnLabel = 'Select wrap',
  isGiftWrapListLoading = false,
  selectedItem,
  isArabic = false,
  perViewSlides = 4.5,
  giftWrappingDisclaimerClass = '',
  removeBtnLabel = '',
  cartItemClassName = '',
  basketItemWidgetTitle = '',
}) => {
  const processingIds = processingItems.map((item) => item.id);

  const filteredCartParentItems =
    cartItems?.filter((item) => !item?.parentId) || [];
  const filteredCartChildItems =
    cartItems?.filter((item) => !!item?.parentId) || [];
  return (
    <div className={className} data-testid='TestId__CartList'>
      {filteredCartParentItems.map((item, index: number) => {
        const subItem = filteredCartChildItems?.find(
          (subCartItem) => subCartItem?.parentId === item?.id
        );
        const additionalNode = (
          <>
            {subItem?.mode === 'invalid' && subItem?.warningText ? (
              <div
                className='fui-flex fui-w-full fui-bg-warning-750 fui-p-9px'
                data-testid='TestId__warningBox'
              >
                <IconWaningAlt className='fui-text-theme-amber-900 ltr:fui-pr-6px rtl:fui-pl-6px' />
                <p className='fui-self-end fui-text-sm fui-font-medium fui-text-theme-neutral-900'>
                  {subItem?.warningText}
                </p>
              </div>
            ) : (
              ''
            )}
          </>
        );
        return (
          <div key={`${item?.id}-${keySuffix}`}>
            <CartItem
              className={cx('fui-mb-3 fui-mt-6', cartItemClassName)}
              imageSrc={item?.imageSrc}
              name={item?.name}
              price={item?.price}
              priceWithVat={item?.priceWithVat}
              qtyLabel={qtyLabel}
              qty={item?.qty}
              id={item?.id}
              value={item?.value}
              description={item?.description}
              extraDetails={item?.extraDetails}
              productDeliveryDuration={item?.productDeliveryDuration}
              productLeadTime={item?.productLeadTime}
              addonBtnLabel={addonBtnLabel}
              mode={item?.mode || mode}
              cakeMessage={item?.cakeMessage}
              cakeMessageLabel={cakeMessageLabel}
              editButtonLabel={editButtonLabel}
              onEditClick={() => onEditClick && onEditClick(item)}
              onAddClick={() => onAddClick && onAddClick(item)}
              onDeleteClick={() => onDeleteClick && onDeleteClick(item, index)}
              onIncrement={(qty) => onIncrement && onIncrement(item, qty)}
              onDecrement={(qty) => onDecrement && onDecrement(item, qty)}
              notSameDayDeliveryMessage={notSameDayDeliveryMessage}
              isProcessing={processingIds.includes(item?.id)}
              imageClass={imageClass}
              isEligibleForGiftWrapping={
                (showExpandedGiftList &&
                  showExpandedGiftList !== 2 &&
                  item?.productStatus !== CartValidationErrorEnum.OutOfStock) ||
                false
              }
              warningText={item?.warningText}
              removeBtnLabel={removeBtnLabel}
              additionalDisclaimer={additionalNode}
            />
            {item?.basketItems?.length ? (
              <BasketItemSection
                basketItemWidgetTitle={basketItemWidgetTitle}
                basketItemsList={item?.basketItems}
              />
            ) : null}
            {subItem &&
            item?.productStatus !== CartValidationErrorEnum.OutOfStock ? (
              <div
                key={`${subItem?.id}-${keySuffix}`}
                className={
                  'fui-relative -fui-mt-3 fui-bg-white fui-px-3 fui-pb-4 fui-pt-2'
                }
              >
                <SubCartItem
                  imageSrc={subItem?.imageSrc}
                  name={subItem?.name}
                  price={subItem?.price}
                  priceWithVat={subItem?.priceWithVat}
                  qty={subItem?.qty}
                  id={subItem?.id}
                  value={subItem?.value}
                  mode={subItem?.mode || 'removeQty'}
                  onEditClick={() => onEditClick && onEditClick(subItem, item)}
                  onDeleteClick={() =>
                    onDeleteClick && onDeleteClick(subItem, index)
                  }
                  isProcessing={processingIds.includes(subItem?.id)}
                  imageClass={imageClass}
                  removeBtnLabel={removeBtnLabel}
                />
              </div>
            ) : (
              <>
                {showExpandedGiftList &&
                showExpandedGiftList !== 2 &&
                item?.productStatus !== CartValidationErrorEnum.OutOfStock ? (
                  <GiftWrapSection
                    showExpandedGiftList={showExpandedGiftList}
                    giftWrappingDisclaimer={giftWrappingDisclaimer}
                    giftWrapWidgetTitle={giftWrapWidgetTitle}
                    giftWrapList={
                      item?.id ? (giftWrapList?.[item?.id] as any) : []
                    }
                    handleOnSelectWrapClick={handleOnSelectWrapClick}
                    giftWrapBtnLabel={giftWrapBtnLabel}
                    isGiftWrapListLoading={isGiftWrapListLoading}
                    selectedItem={selectedItem}
                    isArabic={isArabic}
                    item={item}
                    perViewSlides={perViewSlides}
                    giftWrappingDisclaimerClass={giftWrappingDisclaimerClass}
                  />
                ) : null}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
