import { useContext } from 'react';

import {
  LocalAppContext,
  type LocalAppContextProps,
  SET_ORDER_TOKEN,
  SET_ORDER_DATA,
  SET_ORDER_ADDRESS_DATA,
  SET_ORDER_CARTITEMS_DATA,
  SET_ORDER_CARD_DATA,
  SET_ORDER_PAYMENT_LIST_DATA,
  SET_APP_ERROR,
  SET_APP_LOADING,
} from '@/Context/LocalAppContext';
import {
  getMyOrderDetails,
  getOrderAddressDetails,
  getOrderCardDetails,
  getOrderCartItemsDetails,
  getOrderDetails,
  getOrderPaymentList,
  getOrderPaymentToken,
} from '@/api/order.service';
import {
  errorLoggerV2,
  ddLogger,
} from '@flowardco/app-shared/helpers/ddClientLogs';
import { addToast } from '@flowardco/flib-util';
import { useLocalTranslator } from './useLocalTranslator';

export interface AppHookInterface {
  appState: LocalAppContextProps;
  appDispatch: any;
  getInitialOrderData: (token: string, lang: string) => Promise<any>;
  getOrderDetailsWithPaymentToken: (
    token: string,
    lang: string
  ) => Promise<any>;
  getCardDataWithPaymentToken: (token: string, lang: string) => Promise<any>;
  getOrderAddressDetailsWithPaymentToken: (
    token: string,
    lang: string
  ) => Promise<any>;
  getCartItemsWithPaymentToken: (token: string, lang: string) => Promise<any>;
  getPaymentListWithPaymentToken: (token: string, lang: string) => Promise<any>;
  getPaymentToken: (token: string, lang: string) => Promise<any>;
  getMyCompletedOrderDetails: (token: string, lang: string) => Promise<any>;
}

export const useLocalApp = (): AppHookInterface => {
  const app = useContext(LocalAppContext);
  const { __T } = useLocalTranslator();
  const { appDispatch } = app;

  const getPaymentToken = async (paymentToken: string, lang: string) => {
    try {
      const res = await getOrderPaymentToken(paymentToken, lang);
      if (res?.token) {
        appDispatch({
          type: SET_ORDER_TOKEN,
          payload: {
            lang,
            paymentToken,
            orderToken: res?.token,
          },
        });
      }
      if (!res?.token) {
        addToast(
          __T(
            res?.message ||
              'Failed Fetching Order Payment Token - No Token Returned'
          ),
          'error'
        );
        ddLogger(
          'Failed Fetching Order Payment Token - No Token Returned',
          res,
          'warn'
        );
        return null;
      }
      return res?.token;
    } catch (error: any) {
      errorLoggerV2('Failed Fetching Order Payment Token', error, {
        metaData: {
          action: 'useLocalApp:getPaymentToken',
        },
      });
      return null;
    }
  };

  const getOrderDetailsWithPaymentToken = async (
    orderToken: string,
    lang: string
  ) => {
    try {
      const res = await getOrderDetails({ lang, orderToken });
      if (!res?.id) {
        addToast(__T('Something went wrong'), 'error');
        ddLogger(
          'Failed Fetching Order Details - No Data Returned',
          res,
          'warn'
        );
        appDispatch({
          type: SET_APP_ERROR,
          payload: true,
        });
        return null;
      }
      appDispatch({
        type: SET_ORDER_DATA,
        payload: res,
      });
      return res;
    } catch (error: any) {
      errorLoggerV2('Failed Fetching Order Details With Token', error, {
        metaData: {
          action: 'useLocalApp:getOrderDetailsWithPaymentToken',
        },
      });
      return null;
    }
  };

  const getOrderAddressDetailsWithPaymentToken = async (
    orderToken: string,
    lang: string
  ) => {
    try {
      const res = await getOrderAddressDetails({ lang, orderToken });
      if (!res?.id) {
        ddLogger(
          'Failed Fetching Order Address Details - No Data Returned',
          res,
          'warn'
        );
      }
      appDispatch({
        type: SET_ORDER_ADDRESS_DATA,
        payload: res,
      });
      return res;
    } catch (error: any) {
      errorLoggerV2('Failed Fetching Order Address Details With Token', error, {
        metaData: {
          action: 'useLocalApp:getOrderAddressDetailsWithPaymentToken',
        },
      });
      return null;
    }
  };

  const getCartItemsWithPaymentToken = async (
    orderToken: string,
    lang: string
  ) => {
    try {
      const res = await getOrderCartItemsDetails({ lang, orderToken });
      if (!res) {
        ddLogger(
          'Failed Fetching Order Cart Items Details - No Data Returned',
          res,
          'warn'
        );
      }
      appDispatch({
        type: SET_ORDER_CARTITEMS_DATA,
        payload: res,
      });
      return res;
    } catch (error: any) {
      errorLoggerV2(
        'Failed Fetching Order Cart Items Details With Token',
        error,
        {
          metaData: {
            action: 'useLocalApp:getCartItemsWithPaymentToken',
          },
        }
      );
      return null;
    }
  };

  const getCardDataWithPaymentToken = async (
    orderToken: string,
    lang: string
  ) => {
    try {
      const res = await getOrderCardDetails({ lang, orderToken });
      if (!res) {
        ddLogger(
          'Failed Fetching Order Card Items Details - No Data Returned',
          res,
          'warn'
        );
      }
      appDispatch({
        type: SET_ORDER_CARD_DATA,
        payload: res,
      });
      return res;
    } catch (error: any) {
      errorLoggerV2(
        'Failed Fetching Order Card Items Details With Token',
        error,
        {
          metaData: {
            action: 'useLocalApp:getCardDataWithPaymentToken',
          },
        }
      );
      return null;
    }
  };

  const getPaymentListWithPaymentToken = async (
    orderToken: string,
    lang: string
  ) => {
    try {
      const res = await getOrderPaymentList({ lang, orderToken });
      if (!res) {
        ddLogger(
          'Failed Fetching Order Payment List Items Details - No Data Returned',
          res,
          'warn'
        );
      }
      appDispatch({
        type: SET_ORDER_PAYMENT_LIST_DATA,
        payload: res,
      });
      return res;
    } catch (error: any) {
      errorLoggerV2(
        'Failed Fetching Order Payment List Items Details With Token',
        error,
        {
          metaData: {
            action: 'useLocalApp:getPaymentListWithPaymentToken',
          },
        }
      );
      return null;
    }
  };

  const getInitialOrderData = async (paymentToken: string, lang: string) => {
    try {
      const token = await getPaymentToken(paymentToken, lang);
      if (!token) {
        appDispatch({
          type: SET_APP_LOADING,
          payload: false,
        });
        return;
      }
      await Promise.allSettled([
        getOrderDetailsWithPaymentToken(token, lang),
        getOrderAddressDetailsWithPaymentToken(token, lang),
        getCartItemsWithPaymentToken(token, lang),
        getCardDataWithPaymentToken(token, lang),
        getPaymentListWithPaymentToken(token, lang),
      ]);
      appDispatch({
        type: SET_APP_LOADING,
        payload: false,
      });
    } catch (error: any) {
      errorLoggerV2('Failed Fetching Order Initial Data', error, {
        metaData: {
          action: 'useLocalApp:getInitialOrderData',
        },
      });
    }
  };

  const getMyCompletedOrderDetails = async (
    paymentToken: string,
    lang: string
  ) => {
    try {
      const token = await getPaymentToken(paymentToken, lang);
      if (!token) {
        return;
      }
      const orderDetails = await getMyOrderDetails({ lang, orderToken: token });
      return orderDetails;
    } catch (error: any) {
      errorLoggerV2('Failed Fetching My Completed Order Details', error, {
        metaData: {
          action: 'useLocalApp:getMyCompletedOrderDetails',
        },
      });
      return null;
    }
  };

  return {
    ...app,
    getInitialOrderData,
    getOrderDetailsWithPaymentToken,
    getCardDataWithPaymentToken,
    getOrderAddressDetailsWithPaymentToken,
    getCartItemsWithPaymentToken,
    getPaymentListWithPaymentToken,
    getPaymentToken,
    getMyCompletedOrderDetails,
  };
};
