import { internalHttpClient, API_SERVICE_URLS } from '@flowardco/flib-helpers';

export const orderClient = internalHttpClient({
  baseURL: API_SERVICE_URLS.ORDER,
});
export const homeClient = internalHttpClient({
  baseURL: API_SERVICE_URLS.HOME,
});
export const orderV2Client = internalHttpClient({
  baseURL: API_SERVICE_URLS.ORDERV2,
});
export const client = internalHttpClient({ baseURL: API_SERVICE_URLS.API2 });

export const addRequestHeaders = ({ lang }: { lang: string }) => {
  const language = lang === 'ar' ? 0 : 1;

  const headers = {
    lang: language,
  };

  return headers;
};
