import * as React from 'react';
import type { SVGProps } from 'react';
import { type Ref, forwardRef } from 'react';
const SvgIconPaymentCard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 19'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M17.469 2.167H2.5a1.5 1.5 0 0 0-1.5 1.5v11c0 .843.656 1.5 1.5 1.5h14.969c.843 0 1.531-.657 1.531-1.5v-11c0-.813-.687-1.5-1.531-1.5m-14.782 1.5h14.594c.125 0 .188.093.188.187v1.313H2.5V3.854c0-.094.063-.187.188-.187m14.594 11H2.688q-.188 0-.188-.188V9.167h14.969v5.312q0 .188-.188.188M7 11.542a.4.4 0 0 0-.375-.375h-2.25a.38.38 0 0 0-.375.375v1.25c0 .218.156.375.375.375h2.25A.38.38 0 0 0 7 12.792zm6 0a.4.4 0 0 0-.375-.375h-4.25a.38.38 0 0 0-.375.375v1.25c0 .218.156.375.375.375h4.25a.38.38 0 0 0 .375-.375z'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconPaymentCard);
export default ForwardRef;
