import React from 'react';
import cx from 'clsx';
import IconTrash from '@flowardco/fui-icons/src/IconTrash';
import IconWaningAlt from '@flowardco/fui-icons/src/IconWaningAlt';
import IconTrashAlt from '@flowardco/fui-icons/src/IconTrashAlt';
import { Button } from '@flowardco/fui-button';
import { QuantityCounter } from '@flowardco/fui-quantity-counter';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import { Picture } from '@flowardco/fui-picture';

export type CardMode = 'normal' | 'add' | 'edit' | 'removeQty' | 'invalid';

export interface CartItemProps {
  id?: number;
  className?: string;
  imageSrc: string;
  imageAlt?: string;
  name: string;
  price: number;
  qtyLabel?: string;
  qty?: number;
  value?: string;
  addonBtnLabel?: string;
  mode?: CardMode;
  description?: string | React.ReactNode;
  extraDetails?: string | React.ReactNode;
  extraDetailsClassName?: string;
  onEditClick?(e: React.MouseEvent): void;
  onAddClick?(e: React.MouseEvent): void;
  onDeleteClick?(e: React.MouseEvent): void;
  onIncrement?(newVal: number): void;
  onDecrement?(newVal: number): void;
  basketItems?: any[];
  cakeMessageLabel?: string;
  cakeMessage?: string;
  cakeMessageLength?: number;
  editButtonLabel?: string;
  cakeMessageTitle?: string;
  productDeliveryDuration?: number;
  productLeadTime?: number;
  subscriptionType?: any;
  type?: number;
  notSameDayDeliveryMessage?: string;
  isProcessing?: boolean;
  priceWithVat: number;
  imageClass?: string;
  isSubItem?: boolean;
  isEligibleForGiftWrapping?: boolean;
  parentId?: boolean;
  warningText?: string;
  removeBtnLabel?: string;
  productStatus?: number;
  additionalDisclaimer?: React.ReactNode;
  customizable?: boolean;
}

export const CartItem: React.FC<CartItemProps> = ({
  className = '',
  name,
  imageSrc,
  imageAlt = '',
  value = '',
  qtyLabel = '',
  qty = 1,
  mode = 'normal',
  cakeMessageLabel = '',
  cakeMessage = '',
  editButtonLabel = '',
  description = '',
  extraDetails = '',
  extraDetailsClassName = '',
  addonBtnLabel = 'Add',
  onEditClick = () => null,
  onAddClick = () => null,
  onDeleteClick = () => null,
  onIncrement = () => null,
  onDecrement = () => null,
  productDeliveryDuration = 0,
  productLeadTime = 0,
  notSameDayDeliveryMessage,
  isProcessing = false,
  imageClass = '',
  isEligibleForGiftWrapping = false,
  warningText = '',
  removeBtnLabel = '',
  additionalDisclaimer = '',
}) => {
  const modeContainer = {
    add: (
      <Button
        label={addonBtnLabel}
        className='fui-w-1/5 fui-justify-center'
        buttonStyle='outlinePrimary'
        size='sm'
        onClick={onAddClick}
        data-testid='TestId__Button'
      />
    ),
    normal: (
      <div className='fui-flex fui-leading-none' data-testid='TestId__Qty'>
        <p data-testid='TestId__QtyNumber'>{qtyLabel}:&nbsp;</p>
        <p>{qty}</p>
      </div>
    ),
    edit: (
      <QuantityCounter
        wrapperClasses='fui-w-2/5'
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        value={qty}
        name={'qty'}
      />
    ),
    removeQty: null,
    invalid: (
      <div className='fui-w-2.5/5' data-testid='TestId__removeCartItem'>
        <button
          onClick={onDeleteClick}
          className='fui-flex fui-cursor-pointer fui-items-center fui-rounded-lg fui-border fui-border-theme-neutral-60 fui-p-2'
        >
          <IconTrashAlt className='fui-relative -fui-top-0.5 fui-text-theme-red-900 ltr:fui-pr-6px rtl:fui-pl-6px' />
          <span className='fui-text-md fui-font-semibold fui-text-theme-neutral-900'>
            {removeBtnLabel}
          </span>
        </button>
      </div>
    ),
  };

  return (
    <div
      className={cx(
        'fui-block fui-w-full fui-overflow-hidden fui-bg-white',
        className,
        isEligibleForGiftWrapping
          ? 'fui-rounded-tl-xl fui-rounded-tr-xl'
          : 'fui-rounded-xl'
      )}
      data-testid='TestId__CartItem'
    >
      {mode === 'invalid' && warningText ? (
        <div
          className='fui-flex fui-w-full fui-bg-warning-750 fui-p-9px'
          data-testid='TestId__warningBox'
        >
          <IconWaningAlt className='fui-text-theme-amber-900 ltr:fui-pr-6px rtl:fui-pl-6px' />
          <p className='fui-self-end fui-text-sm fui-font-medium fui-text-theme-neutral-900'>
            {warningText}
          </p>
        </div>
      ) : (
        additionalDisclaimer
      )}
      <div className='fui-relative fui-flex fui-w-full fui-p-4'>
        {isProcessing && (
          <div
            data-testid='TestId__LoadingContainer'
            className='fui-absolute fui-left-0 fui-top-0 fui-z-10 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-backdrop-blur-xs'
          >
            <LoadingSpinner
              logo={false}
              iconClassName='fui-text-salmon-900 fui-w-6 fui-h-6'
            />
          </div>
        )}
        <div className='fui-w-20 fui-shrink-0'>
          <Picture
            src={imageSrc}
            mobileSrc={imageSrc}
            imgTestId={'TestId__CartImg'}
            imageClass={cx(
              'fui-w-full fui-rounded-lg fui-max-h-[87px]',
              imageClass
            )}
            alt={imageAlt || name}
            imgContainerClassName='fui-aspect-square'
          />
        </div>
        <div
          data-testid='TestId__CartItemData'
          className='fui-relative fui-flex fui-w-full fui-flex-col fui-justify-between ltr:fui-pl-2 rtl:fui-pr-2'
        >
          <div className='fui-block'>
            <div className='fui-flex fui-justify-between'>
              <div className='fui-w-full'>
                <div className='fui-flex fui-justify-between'>
                  <h5
                    data-testid='TestId__CartItemName'
                    className='fui-text-base fui-font-medium'
                  >
                    {name}
                  </h5>
                  {mode === 'edit' || mode === 'removeQty' ? (
                    <button
                      onClick={onDeleteClick}
                      className={
                        'fui-h-16px fui-w-16px fui-flex-shrink-0 fui-cursor-pointer fui-text-gray-400'
                      }
                      data-testid='TestId__removeBtn'
                    >
                      <IconTrash />
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                {extraDetails ? (
                  <p
                    data-testid='TestId__CartItemExtraDetails'
                    className={cx(
                      'fui-text-sm fui-font-normal',
                      extraDetailsClassName
                    )}
                  >
                    {extraDetails}
                  </p>
                ) : (
                  ''
                )}

                {!!productDeliveryDuration && !productLeadTime ? (
                  <p
                    className='fui-block fui-py-1 fui-text-sm fui-text-warning-700'
                    data-testid='TestId__NotSameDayDeliveryMessage'
                  >
                    {notSameDayDeliveryMessage}
                  </p>
                ) : (
                  ''
                )}
                {cakeMessage ? (
                  <div className='fui-flex fui-justify-between fui-py-2 fui-text-sm'>
                    <div className='fui-flex'>
                      <p
                        className='fui-break-words'
                        data-testid='TestId__CakeMessage'
                      >
                        {cakeMessageLabel}:&nbsp;{cakeMessage}
                      </p>
                    </div>
                    {mode === 'edit' ? (
                      <button
                        onClick={onEditClick}
                        data-testid='TestId__EditButton'
                        className='fui-self-start fui-text-navy-500 fui-underline'
                      >
                        {editButtonLabel}
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {description && mode === 'normal' ? (
              <div
                className='fui-mb-2 fui-break-words fui-text-sm'
                data-testid='TestId__description'
                dangerouslySetInnerHTML={{ __html: `${description}` }}
              />
            ) : (
              ''
            )}
          </div>
          <div className='fui-flex fui-w-full fui-items-center fui-justify-between'>
            <p className='fui-w-4/5 fui-self-center fui-text-md fui-font-bold fui-leading-none'>
              {value}
            </p>
            {modeContainer[mode]}
          </div>
        </div>
      </div>
    </div>
  );
};
