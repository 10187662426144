import React from 'react';
import { BasketItemsList } from '@flowardco/ui/BasketItemsList';
import type { BasketItemSectionProps } from './BasketItemSection.types';
export const BasketItemSection = ({
  basketItemWidgetTitle = "What's included?",
  basketItemsList = [],
}: BasketItemSectionProps) => {
  if (!basketItemsList?.length) {
    return null;
  }
  return (
    <div className='fui-relative -fui-mt-3 fui-rounded fui-bg-white fui-p-4 fui-pt-3'>
      <hr className='fui-my-1 fui-h-px fui-border-t fui-border-t-gray-330' />
      <div className='fui-mb-[11.5px] fui-mt-3 fui-flex fui-items-center fui-gap-8px'>
        <h3 className='fui-px-6px fui-text-md fui-font-medium fui-text-black-900'>
          {basketItemWidgetTitle}
        </h3>
        <span className='fui-flex fui-h-18px fui-w-18px fui-items-center fui-justify-center fui-overflow-hidden fui-rounded-[30px] fui-bg-theme-navy-150 fui-px-6px fui-py-5px fui-text-xs fui-text-black-700'>
          {basketItemsList?.length}
        </span>
      </div>
      {/* Basket Items List */}
      <BasketItemsList basketItemsList={basketItemsList} />
    </div>
  );
};
