import React from 'react';
import cx from 'clsx';
import IconTrash from '@flowardco/fui-icons/src/IconTrash';

export interface PaymentMethodProp {
  id: number;
  icon: string;
  cardID: number;
  defaultMethod: boolean;
  note: string;
  title: string;
  savedCard: boolean;
  paymentVendor: number;
  validApplePay?: boolean;
  paymentMethodString?: string;
  isCredit?: boolean;
  savedCardId?: string;
  isExpired?: boolean;
}

export interface PaymentMethodProps {
  onChange?(item: PaymentMethodProp): void;
  onDelete?(item: PaymentMethodProp): void;
  paymentMethod: any;
  selectedPayment: PaymentMethodProp | undefined;
  __T: any;
}
export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  paymentMethod,
  onChange,
  onDelete,
  selectedPayment,
  __T,
}) => {
  const checked =
    paymentMethod?.id === Number(selectedPayment?.id) &&
    paymentMethod?.cardID === selectedPayment?.cardID;

  return (
    <div
      className='fui-mb-4 fui-w-full fui-px-[7.5px]'
      data-testid='TestId__PaymentMethod'
    >
      <div
        data-testid='TestId__PaymentMethodElement'
        className={cx(
          'fui-relative fui-block fui-rounded fui-border fui-border-gray-310',
          checked
            ? 'fui-border-2 fui-border-navy-500 fui-text-navy-500'
            : ' fui-text-black-200'
        )}
      >
        <label className='fui-flex fui-h-full fui-w-full fui-items-center fui-gap-26px fui-rounded fui-px-15px fui-py-12px fui-text-center fui-align-middle hover:fui-cursor-pointer'>
          <input
            className={cx(
              'fui-scale-125 fui-transform fui-rounded-full fui-border-2 fui-border-navy-500 fui-text-navy-500 focus:fui-ring-0',
              checked ? '!fui-bg-navy-500' : '',
              paymentMethod?.isExpired && paymentMethod?.savedCard
                ? '!fui-border-gray-500'
                : ''
            )}
            checked={checked}
            type='radio'
            name='payment-method'
            id={`${paymentMethod?.id}-${paymentMethod?.cardID}-${
              paymentMethod?.savedCardId || ''
            }`}
            onChange={() => {
              if (onChange) {
                onChange(paymentMethod);
              }
            }}
            value={paymentMethod?.id}
            data-testid='TestId__PaymentMethodInput'
            disabled={paymentMethod?.isExpired && paymentMethod?.savedCard}
          />

          <div className='fui-flex fui-w-full fui-items-center fui-justify-between'>
            <div className='fui-flex fui-items-center fui-align-middle'>
              <div
                data-testid='TestId__PaymentMethodBox'
                className={cx(
                  'fui-inline-block',
                  paymentMethod?.isExpired && paymentMethod?.savedCard
                    ? 'fui-opacity-40'
                    : ''
                )}
              >
                <img
                  src={paymentMethod?.icon}
                  alt={paymentMethod?.title}
                  width={48}
                  height='auto'
                  className='fui-inline-block'
                />
              </div>
              <div className='fui-inline-block'>
                <p
                  className={cx(
                    'fui-text-left ltr:fui-pl-4 rtl:fui-pr-4 rtl:fui-text-right',
                    paymentMethod?.isExpired && paymentMethod?.savedCard
                      ? 'fui-text-theme-neutral-350'
                      : '',
                    checked
                      ? 'fui-font-bold fui-antialiased'
                      : 'fui-font-medium'
                  )}
                >
                  {paymentMethod?.title}
                </p>
                {paymentMethod?.savedCard && (
                  <p
                    data-testid='TestId__PaymentMethodExpiryDate'
                    className={cx(
                      'fui-text-left fui-text-md fui-font-normal ltr:fui-pl-4 rtl:fui-pr-4',
                      paymentMethod?.isExpired
                        ? 'fui-text-theme-red-900'
                        : 'fui-text-black-100'
                    )}
                  >
                    {paymentMethod?.isExpired
                      ? __T('Expired')
                      : __T('Expiry date')}{' '}
                    {''}
                    {paymentMethod?.expiryDate}
                  </p>
                )}
                {checked && (
                  <p className='font-normal fui-text-left fui-text-sm fui-text-black-900 ltr:fui-pl-3 rtl:fui-pr-3'>
                    {paymentMethod?.note}
                  </p>
                )}
              </div>
            </div>

            {paymentMethod?.savedCard && (
              <button
                onClick={() => {
                  if (onDelete) {
                    onDelete(paymentMethod);
                  }
                }}
                className={
                  'fui-h-20px fui-w-20px fui-cursor-pointer fui-text-theme-neutral-500'
                }
                data-testid='TestId__removeBtn'
              >
                <IconTrash />
              </button>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};
