import React from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconReceipt from '@flowardco/fui-icons/src/IconReceipt';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { TotalSummary } from '@flowardco/ui/TotalSummary';
import { formatPrice } from '@flowardco/flib-util';
import { COUNTRY_CODES } from '@flowardco/app-shared/lib/constants';

const summaryLabel = {
  summary_subTotal: 'Subtotal',
  summary_discount: 'Discount',
  summary_deliveryCost: 'Delivery Charge',
  summary_vat: 'VAT({value}%)',
  summary_grandTotal: 'Total',
};

export const OrderTotalSummary = ({ orderData = {} }: { orderData: any }) => {
  const { __T, __TK } = useLocalTranslator();

  const summaryListItems: any[] = Object.keys(orderData?.summary).map((key) => {
    const title = `${summaryLabel[`summary_${key}`]}`;
    let amount = orderData?.summary?.[key];
    const vatVal = orderData?.summary?.vat / 100;
    if (vatVal && key === 'vat') {
      amount = orderData?.summary?.subTotal * vatVal;
    }
    const amountString = formatPrice(
      amount,
      __TK(orderData?.operation?.currency, orderData?.operation?.currencyAr),
      COUNTRY_CODES[orderData?.operation?.countryId || 2]
    );
    return {
      title: __T(title, { value: orderData?.summary?.[key] }),
      amount,
      value: amountString,
      id: key,
      hidden: amount === 0,
      isTotal: key === 'grandTotal',
    };
  });

  return (
    <div className='fui-block fui-pb-2 fui-pt-3'>
      <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
        <span>
          <IconHeading title={__T('Summary')} icon={<IconReceipt />} />
        </span>
        <span />
      </div>
      <div className='block fui-px-2'>
        <TotalSummary
          summaryListItems={summaryListItems?.filter(
            (item: any) => !item?.hidden && !item?.isTotal
          )}
          totalData={summaryListItems?.find((item) => item?.isTotal)}
        />
      </div>
    </div>
  );
};
