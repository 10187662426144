import React from 'react';
import cx from 'clsx';

export interface ArrowButtonProps extends React.ComponentProps<'button'> {
  icon: React.ReactNode;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  isLeft?: boolean;
  isRight?: boolean;
}

export const ArrowButton: React.FC<ArrowButtonProps> = ({
  icon,
  isRight = false,
  isLeft = false,
  disabled = false,
  leftArrowClassName = '',
  rightArrowClassName = '',
  onClick,
}) => {
  return (
    <button
      className={cx(
        'fui-absolute fui-top-[45%] fui-inline-flex fui-w-6 fui-translate-y-[-50%] fui-justify-center fui-align-middle rtl:fui-rotate-180',
        {
          'ltr:fui-left-0 rtl:fui-right-0': isLeft,
          'ltr:fui-right-0 rtl:fui-left-0': isRight,
          [leftArrowClassName]: isLeft,
          [rightArrowClassName]: isRight,
          'fui-opacity-50': disabled,
        }
      )}
      disabled={disabled}
      onClick={onClick}
      data-testid={`TestId__ArrowButton${isLeft ? 'Left' : 'Right'}`}
    >
      {icon}
    </button>
  );
};
