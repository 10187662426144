import React from 'react';
import { Snackbar } from '@flowardco/fui-snackbar';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

export const UserHero = ({ orderData }: { orderData: any }) => {
  const { __T } = useLocalTranslator();
  return (
    <Snackbar type='info' className='!fui-bg-blue-600/20'>
      <p className='fui-pb-2 fui-text-base fui-text-theme-navy-850'>
        {__T('Hello {name},', { name: orderData?.userName })}
      </p>
      <p className='fui-block fui-py-1 fui-text-md'>
        {__T('The following are the details for order #{orderid}', {
          orderid: orderData?.id,
        })}
      </p>
      <p className='fui-block fui-py-1 fui-text-md'>
        {__T('Please review them before payment')}
      </p>
    </Snackbar>
  );
};
