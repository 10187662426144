import React from 'react';
import IconTickRounded from '@flowardco/fui-icons/src/IconTickRounded';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { IconHeading } from '@flowardco/fui-icon-heading';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import IconCalendarNew from '@flowardco/fui-icons/src/IconCalendarNew';
import { AddressCard } from '@flowardco/fui-address-card';
import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import { OrderCartList } from './OrderCartList';
import IconReceipt from '@flowardco/fui-icons/src/IconReceipt';
import { TotalSummary } from '@flowardco/ui/TotalSummary';
import { formatPrice } from '@flowardco/flib-util';
import { OrderPaymentDetailType } from '@flowardco/flib-models';

const formatCartTotalSummary = (
  totals: any[],
  __T: any,
  currency: string,
  decimalNumber: any
) => {
  return totals.map((item) => ({
    ...item,
    color:
      item?.id === 3 && item?.amount === 0 && !item?.color
        ? '#A3C362'
        : item?.color,
    value:
      item?.id === 3 && item?.amount === 0
        ? __T('Free')
        : formatPrice(item?.amount || 0, currency, decimalNumber || 0),
    subTitle: undefined,
  }));
};

export const MyOrderDetailsCard = ({ orderData }: any) => {
  const { __T, __TK } = useLocalTranslator();
  const orderTotalSummary = formatCartTotalSummary(
    orderData?.paymentDetails,
    __T,
    __TK(orderData?.operation?.currency, orderData?.operation?.currencyAr),
    orderData?.operation?.decimalNumber
  );
  return (
    <AppErrorBoundary>
      <AppErrorBoundary>
        <div className='fui-flex fui-w-full fui-flex-col fui-items-center fui-justify-center fui-px-4 fui-py-2 fui-align-middle'>
          <IconTickRounded
            className='fui-h-32 fui-w-32 fui-text-theme-navy-900'
            strokeWidth={1}
          />
          <h1 className='fui-py-2 fui-pt-4 fui-text-center fui-text-lg fui-font-medium'>
            {__T('Order confirmed')}
          </h1>
          <h2 className='fui-py-2 fui-text-center fui-text-base fui-font-normal'>
            {__T('Order No: #{orderid}', { orderid: orderData?.id })}
          </h2>
        </div>
      </AppErrorBoundary>
      <AppErrorBoundary>
        <div className='fui-block fui-pt-3'>
          <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
            <span>
              <IconHeading
                title={__T('Delivery Time')}
                icon={<IconCalendarNew />}
              />
            </span>
          </div>
          <div className='fui-dir-ltr fui-rounded-md fui-border fui-border-gray-250 fui-p-3 ltr:fui-text-left rtl:fui-text-right'>
            {orderData?.deliveryDateString}
          </div>
        </div>
      </AppErrorBoundary>
      <AppErrorBoundary>
        <div className='fui-block fui-pt-3'>
          <div className='fui-block fui-px-1 fui-py-3'>
            <IconHeading title={__T('Deliver To')} icon={<IconMapMarker />} />
          </div>
          <div className='fui-rounded-md fui-border fui-border-gray-250'>
            <AddressCard
              iconUrl={null}
              postalCode={orderData?.shippingAddress?.postalCode}
              name={orderData?.shippingAddress?.addressLineOne}
              addressLine={orderData?.shippingAddress?.addressLineTwo}
              recipientName={orderData?.shippingAddress?.recipientName}
              recipientPhone={orderData?.shippingAddress?.recipientPhone}
              isEditMode={false}
              nameLabel={__T('Name')}
              phoneLabel={__T('Phone')}
              className='!fui-mb-0 fui-rounded-md !fui-border-0'
            />
          </div>
        </div>
      </AppErrorBoundary>
      <AppErrorBoundary>
        <div className='fui-block'>
          <OrderCartList data={orderData?.cart} orderData={orderData} />
        </div>
      </AppErrorBoundary>
      <AppErrorBoundary>
        <div className='fui-block fui-pb-2 fui-pt-3'>
          <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
            <span>
              <IconHeading title={__T('Summary')} icon={<IconReceipt />} />
            </span>
            <span />
          </div>
          <div className='block fui-px-2'>
            <TotalSummary
              summaryListItems={orderTotalSummary?.filter(
                (item: any) =>
                  !item?.hidden &&
                  item?.id !== OrderPaymentDetailType.GrandTotal
              )}
              totalData={orderTotalSummary?.find(
                (item) => item?.id === OrderPaymentDetailType.GrandTotal
              )}
            />
          </div>
        </div>
      </AppErrorBoundary>
    </AppErrorBoundary>
  );
};
