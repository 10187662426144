import React from 'react';
import { ExperimentA } from './ExperimentA';
import { ExperimentB } from './ExperimentB';
import type { ExperimentProps } from './GiftWrapSection.types';

export * from './GiftWrapSection.types';

export const GiftWrapSection = ({
  showExpandedGiftList = 2,
  giftWrappingDisclaimer = 'Only gifts and non-flower products will be wrapped',
  giftWrapWidgetTitle = 'Gift wrapping',
  giftWrapList = [],
  handleOnSelectWrapClick = () => null,
  giftWrapBtnLabel = 'Select wrap',
  isGiftWrapListLoading = false,
  selectedItem = {},
  isArabic = false,
  item = {},
  perViewSlides = 4.5,
  giftWrappingDisclaimerClass = '',
}: ExperimentProps) => {
  return (
    <div>
      {showExpandedGiftList === 1 ? (
        <ExperimentA
          showExpandedGiftList={showExpandedGiftList}
          giftWrappingDisclaimer={giftWrappingDisclaimer}
          giftWrapWidgetTitle={giftWrapWidgetTitle}
          giftWrapList={giftWrapList}
          handleOnSelectWrapClick={handleOnSelectWrapClick}
          giftWrapBtnLabel={giftWrapBtnLabel}
          isGiftWrapListLoading={isGiftWrapListLoading}
          selectedItem={selectedItem}
          isArabic={isArabic}
          item={item}
          perViewSlides={perViewSlides}
          giftWrappingDisclaimerClass={giftWrappingDisclaimerClass}
        />
      ) : (
        <ExperimentB
          giftWrapWidgetTitle={giftWrapWidgetTitle}
          giftWrapList={giftWrapList}
          handleOnSelectWrapClick={handleOnSelectWrapClick}
          giftWrapBtnLabel={giftWrapBtnLabel}
          item={item}
        />
      )}
    </div>
  );
};
