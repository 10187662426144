import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Modal } from '@flowardco/fui-modal';
import cx from 'clsx';
import IconTrash from '@flowardco/fui-icons/src/IconTrash';
import { deleteCardMessage, updateCardMessage } from '@/api/order.service';
import { useState } from 'react';
import { Button } from '@flowardco/fui-button';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { useLocalApp } from '@/hooks/useLocalApp';
import { addToast } from '@flowardco/flib-util';
import { CardMessage as CardMessageComponent } from '@flowardco/ui/CardMessage';

export const CardMessageModal = ({ onCloseModal }: { onCloseModal: any }) => {
  const { __T } = useLocalTranslator();
  const {
    appState: { lang, orderToken, orderCardData, orderData },
    getCardDataWithPaymentToken,
  } = useLocalApp();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const cardMsgData = {
    cardTo: orderCardData?.data?.messageTo || '',
    cardMessage: orderCardData?.data?.messageText || '',
    cardFrom: orderCardData?.data?.messageFrom || '',
    feelingLink: orderCardData?.data?.feelingLink || '',
    isBraillePrint: orderCardData?.data?.isBraillePrint || false,
  };
  const hasCardData =
    orderCardData?.data?.messageTo ||
    orderCardData?.data?.messageText ||
    orderCardData?.data?.messageFrom ||
    orderCardData?.data?.feelingLink;

  const onSubmitCard = async (cardData: any) => {
    if (orderToken) {
      setIsProcessing(true);
      const data = await updateCardMessage({
        lang: lang,
        orderToken: orderToken,
        cardData,
      });
      setIsProcessing(false);
      if (data?.success) {
        await getCardDataWithPaymentToken(orderToken, lang);
        addToast(__T(data?.message), 'success');
        onCloseModal();
      }
    }
  };
  const onDeleteCard = async () => {
    if (orderToken) {
      setIsProcessing(true);
      const data = await deleteCardMessage({
        lang: lang,
        orderToken: orderToken,
      });
      setIsProcessing(false);
      if (data?.success) {
        await getCardDataWithPaymentToken(orderToken, lang);
        addToast(__T(data?.message), 'success');
        onCloseModal();
      }
    }
  };

  return (
    <Modal onBackdropClose={onCloseModal} onIconClose={onCloseModal} v2={true}>
      <div className='fui-block fui-border-b fui-border-b-theme-neutral-200'>
        <h3 className='fui-block fui-pb-5 fui-text-base fui-font-semibold fui-text-black-700'>
          {__T('Card Message')}
        </h3>
      </div>
      <AppErrorBoundary>
        <div className='fui-mt-4 fui-rounded fui-bg-white fui-p-4'>
          <CardMessageComponent
            isBraille={orderData?.operation?.countryId === 2}
            initialValues={cardMsgData}
            className='!fui-border-0 !fui-p-0'
            __T={__T}
            onSubmitCard={onSubmitCard}
            qrCodeLink={
              cardMsgData?.feelingLink !== ''
                ? cardMsgData?.feelingLink
                : 'https://floward.com/'
            }
          />
        </div>
      </AppErrorBoundary>
      <div className='fui-mt-2 fui-flex fui-flex-nowrap fui-items-center fui-justify-between fui-border-t fui-border-salmon-200 fui-py-4 fui-align-middle'>
        {hasCardData ? (
          <div
            className={cx(
              'fui-flex fui-w-1/3 fui-items-center fui-justify-center fui-px-4 fui-align-middle'
            )}
          >
            <button
              className='fui-flex fui-w-full fui-min-w-0 fui-shrink-0 fui-flex-nowrap fui-items-center fui-justify-center fui-align-middle fui-text-sm fui-font-medium fui-text-theme-red-900'
              type='button'
              onClick={onDeleteCard}
            >
              <span className='fui-inline-block fui-h-4.5 fui-w-4.5'>
                <IconTrash />
              </span>
              <span className='fui-inline-block fui-px-1'>{__T('Delete')}</span>
            </button>
          </div>
        ) : (
          ''
        )}
        <div
          className={cx('fui-px-4', hasCardData ? 'fui-w-2/3' : 'fui-w-full')}
        >
          <Button
            label={__T('Confirm')}
            type='submit'
            form='cardMessage'
            className='fui-w-full'
            id='card_button'
            isProcessing={isProcessing}
            disabled={isProcessing}
          />
        </div>
      </div>
    </Modal>
  );
};
