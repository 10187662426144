import { Carousel, CarouselSlide } from '@flowardco/fui-carousel';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { BasketItemsListProps } from './BasketItemsList.types';
import IconArrowLeft from '@flowardco/fui-icons/src/IconArrowLeft';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import { BasketItem } from './BasketItem';
export const BasketItemsList: React.FC<BasketItemsListProps> = ({
  className = '',
  basketItemsList,
  isArabic = false,
  activeSlide = 0,
  perViewSlides = 4.5,
}) => {
  return (
    <div className={className} data-testid='TestId__BasketItemsList'>
      <Carousel
        showArrows={basketItemsList?.length > perViewSlides ? true : undefined}
        carouselOptions={{
          breakpoints: {
            '(min-width: 976px)': {
              slides: { perView: perViewSlides },
            },
          },
          loop: false,
          rtl: isArabic,
          mode: 'free',
          initial: activeSlide,
          slides: { perView: perViewSlides },
        }}
        bulletPointsClassName='!fui-bg-opacity-100'
        leftArrowClassName='!fui-w-auto fui-drop-shadow-button -fui-mt-16px'
        rightArrowClassName='!fui-w-auto fui-drop-shadow-button -fui-mt-15px'
        currentActiveSlide={activeSlide}
        leftArrowIcon={
          <div
            className={twMerge(
              'fui-flex fui-h-6 fui-w-6 fui-justify-center fui-rounded-3xl fui-border fui-border-solid fui-bg-white fui-text-black-700',
              'fui-ml-0'
            )}
            data-testid='TestId__BasketItemsListSliderLeftArrow'
          >
            <IconArrowLeft
              className={twMerge('fui-text-black-700', 'fui-w-1.5')}
              strokeWidth={1.5}
            />
          </div>
        }
        rightArrowIcon={
          <div
            className={twMerge(
              'fui-flex fui-h-6 fui-w-6 fui-justify-center fui-rounded-3xl fui-border fui-border-solid fui-bg-white fui-text-black-700',
              'fui-mr-0'
            )}
            data-testid='TestId__BasketItemsListSliderRightArrow'
          >
            <IconArrowRight
              className={twMerge('fui-text-black-700', 'fui-w-1.5')}
              strokeWidth={1.5}
            />
          </div>
        }
      >
        {basketItemsList?.map((item: any, idx: number) => {
          return (
            <CarouselSlide key={`${item?.proId}-${idx}`}>
              <div className={twMerge('fui-w-full')}>
                <BasketItem {...item} />
              </div>
            </CarouselSlide>
          );
        })}
      </Carousel>
    </div>
  );
};
