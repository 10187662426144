import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Modal } from '@flowardco/fui-modal';
import cx from 'clsx';
import IconTrash from '@flowardco/fui-icons/src/IconTrash';
import { type Dispatch, type SetStateAction, useRef } from 'react';
import { Button } from '@flowardco/fui-button';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { type PaymentDataProps } from './PaymentOptionsList';
import { TextareaField } from '@flowardco/fui-input-field';

export const SenderNotesModal = ({
  onCloseModal,
  paymentDataState,
  setPaymentDataState,
}: {
  onCloseModal: any;
  paymentDataState: PaymentDataProps;
  setPaymentDataState: Dispatch<SetStateAction<PaymentDataProps>>;
}) => {
  const notesRef = useRef<any>(null);
  const { __T } = useLocalTranslator();
  const hasData = paymentDataState?.noteForFloward;
  const onAddNotes = async () => {
    setPaymentDataState({
      ...paymentDataState,
      noteForFloward: notesRef?.current?.value,
    });
    onCloseModal();
  };
  const onDelete = async () => {
    setPaymentDataState({ ...paymentDataState, noteForFloward: '' });
    onCloseModal();
  };

  return (
    <Modal onBackdropClose={onCloseModal} onIconClose={onCloseModal} v2={true}>
      <div className='fui-block fui-border-b fui-border-b-theme-neutral-200'>
        <h3 className='fui-block fui-pb-5 fui-text-base fui-font-semibold fui-text-black-700'>
          {__T('Note to Floward')}
        </h3>
      </div>
      <AppErrorBoundary>
        <div className='fui-mt-4 fui-rounded fui-bg-white'>
          <TextareaField
            name='floward-note'
            placeholder={__T(
              'Write your notes for the Floward team,\neg: deliver before 11 AM'
            )}
            labelClass='fui-text-navy-500 fui-text-base fui-mt-3'
            wrapperClassName='fui-px-[7.5px] md:fui-mb-1 md:fui-mb-8'
            otherProps={{ ref: notesRef, maxLength: 200 }}
            value={paymentDataState?.noteForFloward}
          />
        </div>
      </AppErrorBoundary>
      <div className='fui-mt-2 fui-flex fui-flex-nowrap fui-items-center fui-justify-between fui-border-t fui-border-salmon-200 fui-py-4 fui-align-middle'>
        {hasData ? (
          <div
            className={cx(
              'fui-flex fui-w-1/3 fui-items-center fui-justify-center fui-px-4 fui-align-middle'
            )}
          >
            <button
              className='fui-flex fui-w-full fui-min-w-0 fui-shrink-0 fui-flex-nowrap fui-items-center fui-justify-center fui-align-middle fui-text-sm fui-font-medium fui-text-theme-red-900'
              type='button'
              onClick={onDelete}
            >
              <span className='fui-inline-block fui-h-4.5 fui-w-4.5'>
                <IconTrash />
              </span>
              <span className='fui-inline-block fui-px-1'>{__T('Delete')}</span>
            </button>
          </div>
        ) : (
          ''
        )}
        <div className={cx('fui-px-4', hasData ? 'fui-w-2/3' : 'fui-w-full')}>
          <Button
            label={__T('Confirm')}
            type='button'
            className='fui-w-full'
            id='card_button'
            onClick={onAddNotes}
          />
        </div>
      </div>
    </Modal>
  );
};
