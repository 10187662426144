import React, { useEffect } from 'react';
import { useCreditCard } from '@flowardco/flib-hooks';
import { crypto } from '@flowardco/flib-helpers';
import { loadExternalScript } from '@flowardco/flib-util';

declare global {
  interface Window {
    Frames: any;
  }
}
export interface CreditCardFormProps {
  checkoutKey?: string;
  onCardTokenized?(cardData: any): void;
  onCardTokenizationFailed?(event: any): void;
  __T: (key?: string) => string;
  isEncrypted?: boolean;
}
export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  checkoutKey = '',
  onCardTokenized = () => null,
  onCardTokenizationFailed = () => null,
  isEncrypted = true,
  __T,
}) => {
  const { initFrames } = useCreditCard({
    __T,
    publicKey: isEncrypted ? crypto.decryptx(checkoutKey) : checkoutKey,
    onCardTokenized,
    onCardTokenizationFailed,
  });
  useEffect(() => {
    loadExternalScript(
      'https://cdn.checkout.com/js/framesv2.min.js',
      initFrames,
      'creditCardScript',
      true
    );
  }, []);
  return <form id='payment-form' method='post'></form>;
};
