import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconWaningAlt = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='none'
    ref={ref}
    {...props}
  >
    <g id='Error Triangle 1'>
      <path
        id='Vector'
        d='M18.2122 19.2632H4.78759C3.3177 19.2632 2.39891 17.6723 3.13338 16.3988L9.88799 4.69275C10.6252 3.41462 12.4713 3.41928 13.202 4.70112L19.8719 16.4072C20.5971 17.6807 19.6774 19.2632 18.2122 19.2632Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M11.5112 12.7292V9.84351M11.5094 15.6477V15.6011'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgIconWaningAlt);
export default ForwardRef;
