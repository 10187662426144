import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconGiftWrap = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M11.3158 20.6899H6.27471C4.32309 20.6899 3.11523 19.2367 3.11523 17.1837V11.6331C3.11523 9.58009 4.32309 8.12695 6.27471 8.12695H13.3964C15.0326 8.12695 16.1523 9.14835 16.4715 10.6884'
      stroke='black'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.74609 12.3691V20.6906'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.83746 7.5021C9.83746 5.51809 11.2148 3.91113 12.9139 3.91113C13.6458 3.91113 14.238 4.50335 14.238 5.23529C14.238 6.83226 12.2673 8.12758 9.83635 8.12758C7.4043 8.12758 5.43359 6.83226 5.43359 5.23529C5.43359 4.50335 6.02691 3.91113 6.75885 3.91113C8.45674 3.91113 9.83413 5.51809 9.83413 7.5021'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.57031 11.7184L9.83808 8.12744L13.1046 11.7184'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1699 14.5386V20.6899'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.0078 14.5391H19.3345C20.29 14.5391 20.8858 15.2505 20.8858 16.2557V18.9733C20.8858 19.9785 20.2862 20.69 19.3345 20.69H15.0078C14.0523 20.69 13.4609 19.9785 13.4609 18.9733V16.2557C13.4609 15.2505 14.0523 14.5391 15.0078 14.5391Z'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1736 14.5393L15.1582 12.5229'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1719 14.5393L19.1873 12.5229'
      stroke='currentColor'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconGiftWrap);
export default ForwardRef;
