import { MyOrderDetailsCard } from '@/components/MyOrderDetailsCard';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import { OrderStatusEnum } from '@flowardco/flib-models';
import { addToast } from '@flowardco/flib-util';
import IconInfoCircle from '@flowardco/fui-icons/src/IconInfoCircle';
import { Snackbar } from '@flowardco/fui-snackbar';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router-dom';

const CheckoutConfirmationPage = () => {
  const { getMyCompletedOrderDetails } = useLocalApp();
  const { lang = 'en', paymentToken = '' } = useParams();
  const { __T } = useLocalTranslator();
  const { isLoading, data: myOrderDetails } = useQuery(
    ['myOrderDetails', { paymentToken }],
    () => {
      return getMyCompletedOrderDetails(paymentToken, lang);
    },
    { cacheTime: 1, enabled: !!paymentToken }
  );
  if (isLoading) {
    return (
      <Fragment>
        <FullPageLoader />
      </Fragment>
    );
  }
  if (!myOrderDetails?.id) {
    return (
      <Fragment>
        <div className='fui-flex fui-w-full fui-flex-col fui-items-center fui-justify-center fui-px-4 fui-py-2 fui-align-middle'>
          <IconInfoCircle className='fui-h-32 fui-w-32 fui-text-theme-navy-900' />
          <h1 className='fui-py-2 fui-pt-4 fui-text-center fui-text-lg fui-font-medium'>
            {__T('No Order found')}
          </h1>
        </div>
      </Fragment>
    );
  }
  if (myOrderDetails?.status === OrderStatusEnum.Not_Confirmed) {
    addToast(
      __T('Your payment was not successful. Please try again.'),
      'error'
    );
    return <Navigate to={`/${lang}/${paymentToken}`} />;
  }
  if (myOrderDetails?.status === OrderStatusEnum.Confirmed) {
    return (
      <div className='fui-p-2 fui-pb-8'>
        <MyOrderDetailsCard orderData={myOrderDetails} />
      </div>
    );
  }

  return (
    <div className='fui-p-2 fui-pb-8'>
      <Fragment>
        <div className='fui-flex fui-w-full fui-flex-col fui-items-center fui-justify-center fui-px-4 fui-py-2 fui-align-middle'>
          <IconInfoCircle className='fui-h-32 fui-w-32 fui-text-theme-navy-900' />
          <h1 className='fui-py-2 fui-pt-4 fui-text-center fui-text-lg fui-font-medium'>
            {__T("We can't process your order. Please try again")}
          </h1>
          <div className='fui-py-4'>
            <Snackbar
              noteTextClass='fui-text-center !fui-text-base !fui-font-medium'
              noteText={__T(
                'Order Status is {orderstatus}. Please contact our customer service.',
                { orderstatus: myOrderDetails?.orderStatusString }
              )}
            />
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default CheckoutConfirmationPage;
