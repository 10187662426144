import React from 'react';
import cx from 'clsx';
import './CreditCardInput.css';

export interface CreditCardInputProps {
  title: string;
  icon?: any;
  name: string;
}

export const CreditCardInput: React.FC<CreditCardInputProps> = ({
  title,
  icon,
  name,
}) => {
  return (
    <>
      <label
        data-testid='TestId__CreditCardInput'
        htmlFor={name}
        className='fui-my-8px fui-block fui-h-auto  fui-font-medium fui-leading-normal	fui-text-black-600'
      >
        {title}
      </label>
      <div className='fui-relative fui-mb-8px fui-flex fui-h-40px fui-bg-white'>
        {icon}
        <div className={cx('fui-w-full fui-px-16px', name + '-frame')}></div>
      </div>
    </>
  );
};
