import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconLock = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 15 16'
    fill='none'
    ref={ref}
    {...props}
  >
    <path
      d='M7.125 12.875C7.59375 12.875 8 12.5 8 12V10C8 9.53125 7.59375 9.125 7.125 9.125C6.625 9.125 6.25 9.53125 6.25 10V12C6.25 12.5 6.625 12.875 7.125 12.875ZM14.125 7.5C14.125 6.6875 13.4375 6 12.625 6H11.625V4.5625C11.625 2.0625 9.625 0.03125 7.125 0C4.625 0 2.625 2.03125 2.625 4.5V6H1.625C0.78125 6 0.125 6.6875 0.125 7.5V14.5C0.125 15.3438 0.78125 16 1.625 16H12.625C13.4375 16 14.125 15.3438 14.125 14.5V7.5ZM4.125 6V4.5C4.125 2.875 5.46875 1.5 7.125 1.5C8.75 1.5 10.125 2.875 10.125 4.5V6H4.125ZM12.625 7.5V14.5H1.625V7.5H12.625Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconLock);
export default ForwardRef;
