import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconTrash = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M18 6v12.75c0 1.243-1.027 2.25-2.269 2.25h-7.5A2.233 2.233 0 0 1 6 18.75V6M19.5 6h-15M10 3h4M14 10v7M10 17v-7'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconTrash);
export default ForwardRef;
