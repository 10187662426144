import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { GiftWrapProps } from './GiftWrapList.types';

export const GiftWrap: React.FC<GiftWrapProps> = ({
  className = '',
  id,
  giftWrapImg,
  price,
  isSelected = false,
  onSelectGiftWrap,
  name = 'giftWrap',
  value,
  cartItemId,
}) => {
  return (
    <div
      className={twMerge('fui-block fui-text-center', className)}
      data-testid='TestId__GiftWrap'
    >
      <label
        htmlFor={`${id}-radio-gift-wrap-${cartItemId}`}
        className='fui-cursor-pointer'
      >
        <input
          type='radio'
          name={`${name}-${cartItemId}`}
          id={`${id}-radio-gift-wrap-${cartItemId}`}
          className={'fui-hidden fui-appearance-none fui-opacity-0'}
          onChange={onSelectGiftWrap}
          checked={isSelected}
          value={value}
          data-testid='TestId__GiftWrapCheckInput'
        />
        <div
          className={twMerge(
            isSelected ? '!fui-border-navy-500 ' : '',
            'fui-rounded-[0.25rem] fui-border-2 fui-border-transparent fui-p-1'
          )}
          data-testid='TestId__GiftWrapImgContainer'
        >
          <div className='fui-relative fui-mb-2'>
            {giftWrapImg ? (
              <img
                data-testid='TestId__GiftWrapImg'
                src={giftWrapImg}
                className={
                  'fui-aspect-square fui-rounded-[0.25rem] fui-object-cover'
                }
                alt='gift-wrap'
              />
            ) : null}
          </div>
          {price ? (
            <p
              data-testid='TestId__GiftWrapPrice'
              className=' fui-text-sm fui-font-medium fui-leading-[16px] fui-text-black-900'
            >
              {price}
            </p>
          ) : null}
        </div>
      </label>
    </div>
  );
};
