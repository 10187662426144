import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconBasket = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 26 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M25.458 7.124h-3.295L16.34.373a1.133 1.133 0 0 0-1.534-.135 1.107 1.107 0 0 0-.136 1.52l4.65 5.366H6.634l4.65-5.365a1.107 1.107 0 0 0-.136-1.52 1.133 1.133 0 0 0-1.534.134l-5.823 6.75H.542A.546.546 0 0 0 0 7.66v1.073a.52.52 0 0 0 .542.537h.812l1.264 8.897C2.753 19.24 3.656 20 4.74 20h16.475c1.084 0 1.986-.76 2.122-1.833L24.6 9.27h.857c.271 0 .542-.224.542-.537V7.66c0-.268-.27-.536-.542-.536Zm-4.243 10.73H4.74L3.566 9.27h18.823l-1.174 8.584Zm-7.132-6.08c0-.582-.496-1.074-1.083-1.074-.632 0-1.083.492-1.083 1.074v3.576c0 .626.451 1.073 1.083 1.073.587 0 1.083-.447 1.083-1.073v-3.576Zm5.056 0c0-.582-.497-1.074-1.083-1.074-.632 0-1.084.492-1.084 1.074v3.576c0 .626.452 1.073 1.084 1.073.586 0 1.083-.447 1.083-1.073v-3.576Zm-10.111 0c0-.582-.497-1.074-1.084-1.074-.631 0-1.083.492-1.083 1.074v3.576c0 .626.452 1.073 1.083 1.073.587 0 1.084-.447 1.084-1.073v-3.576Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconBasket);
export default ForwardRef;
