import React from 'react';
import cx from 'clsx';
import IconTrash from '@flowardco/fui-icons/src/IconTrash';
import IconEdit from '@flowardco/fui-icons/src/IconEdit';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import { Picture } from '@flowardco/fui-picture';
import IconTrashAlt from '@flowardco/fui-icons/src/IconTrashAlt';

export interface SubCartItemProps {
  id?: number;
  className?: string;
  imageSrc: string;
  imageAlt?: string;
  name: string;
  price: number;
  qtyLabel?: string;
  qty?: number;
  value?: string;
  onEditClick(e: React.MouseEvent): void;
  onDeleteClick(e: React.MouseEvent): void;
  type?: number;
  isProcessing?: boolean;
  priceWithVat: number;
  imageClass?: string;
  mode?: string;
  removeBtnLabel?: string;
}

export const SubCartItem: React.FC<SubCartItemProps> = ({
  className = '',
  name,
  imageSrc,
  imageAlt = '',
  value = '',
  qty = 1,
  onEditClick,
  onDeleteClick,
  isProcessing = false,
  imageClass = '',
  mode = '',
  removeBtnLabel = '',
}) => {
  return (
    <div
      className={cx(
        'fui-relative fui-flex fui-w-full fui-rounded fui-border fui-border-solid fui-border-gray-250 fui-bg-white fui-p-8px',
        className
      )}
      data-testid='TestId__SubCartItem'
    >
      {isProcessing && (
        <div
          data-testid='TestId__LoadingContainer'
          className='fui-absolute fui-left-0 fui-top-0 fui-z-10 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-backdrop-blur-xs'
        >
          <LoadingSpinner
            logo={false}
            iconClassName='fui-text-salmon-900 fui-w-6 fui-h-6'
          />
        </div>
      )}
      <div className='fui-w-16 fui-shrink-0'>
        <Picture
          src={imageSrc}
          mobileSrc={imageSrc}
          imgTestId={'TestId__CartImg'}
          imageClass={cx('fui-rounded fui-w-full', imageClass)}
          alt={imageAlt || name}
        />
      </div>
      <div
        data-testid='TestId__SubCartItemData'
        className='fui-relative fui-flex fui-w-full fui-flex-col fui-justify-between ltr:fui-pl-3.5 rtl:fui-pr-3.5'
      >
        <div className='fui-flex fui-justify-between'>
          <h5
            data-testid='TestId__SubCartItemName'
            className='fui-text-md fui-font-medium fui-text-black-700'
          >
            {name}
          </h5>

          {mode === 'invalid' ? (
            <div className='fui-w-2.5/5' data-testid='TestId__removeCartItem'>
              <button
                onClick={onDeleteClick}
                className='fui-flex fui-cursor-pointer fui-items-center fui-rounded-lg fui-border fui-border-theme-neutral-60 fui-p-2'
              >
                <IconTrashAlt className='fui-relative -fui-top-0.5 fui-text-theme-red-900 ltr:fui-pr-6px rtl:fui-pl-6px' />
                <span className='fui-text-md fui-font-semibold fui-text-theme-neutral-900'>
                  {removeBtnLabel}
                </span>
              </button>
            </div>
          ) : (
            <div className='fui-mt-14px fui-flex fui-justify-between fui-gap-4'>
              <button
                onClick={onEditClick}
                data-testid='TestId__EditButton'
                className='fui-h-16px fui-w-16px  fui-self-start fui-text-navy-500 fui-underline'
              >
                <IconEdit />
              </button>

              <button
                onClick={onDeleteClick}
                className={
                  'fui-h-16px fui-w-16px fui-cursor-pointer fui-text-gray-400'
                }
                data-testid='TestId__removeBtn'
              >
                <IconTrash />
              </button>
            </div>
          )}
        </div>
        <p className='w-full fui-dir-ltr fui-flex fui-text-md fui-font-semibold fui-leading-[18px] fui-text-black-100 ltr:fui-justify-start rtl:fui-justify-end'>
          <span className='fui-inline fui-pr-1'>{`${qty}x`}</span>
          <span className='fui-inline'>{value}</span>
        </p>
      </div>
    </div>
  );
};
