import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import React from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconBasket from '@flowardco/fui-icons/src/IconBasket';
import { CartList } from '@flowardco/fui-cart-list';
import { formatPrice } from '@flowardco/flib-util';
import { COUNTRY_CODES } from '@flowardco/app-shared/lib/constants';

export const OrderCartList = ({
  data = [],
  orderData = {},
}: {
  data: any[];
  orderData: any;
}) => {
  const { __T, __TK } = useLocalTranslator();

  const cartItems =
    data?.map((item: any) => {
      return {
        ...item,
        description: '',
        name: __TK(
          item?.productName || item?.name,
          item?.productNameAr || item?.name
        ),
        imageSrc: item.image,
        value: formatPrice(
          item.price,
          __TK(
            orderData?.operation?.currency,
            orderData?.operation?.currencyAr
          ),
          COUNTRY_CODES[orderData?.operation?.countryId || 2]
        ),
      };
    }) || [];
  return (
    <div className='fui-block fui-pt-2'>
      <div className='fui-block fui-px-1 fui-py-3'>
        <IconHeading title={__T('Ordered Items')} icon={<IconBasket />} />
      </div>
      <div className='fui-block'>
        <CartList
          cartItems={cartItems}
          qtyLabel={__T('QTY')}
          mode='normal'
          imageClass='!fui-max-h-[114px]'
          cakeMessageLabel={__T('Message')}
          cartItemClassName='fui-mb-2 !fui-mt-0 fui-border fui-border-gray-250'
          notSameDayDeliveryMessage={__T(
            'This item is not available for same day delivery'
          )}
        />
      </div>
    </div>
  );
};
