import React from 'react';
import { LoadingSkeleton } from './LoadingSkeleton';
import { GiftWrapList } from '@flowardco/ui/GiftWrapList';
import IconWarning from '@flowardco/fui-icons/src/IconWarning';
import { ProductType } from '@flowardco/flib-models';
import IconGiftWrap from '@flowardco/fui-icons/src/IconGiftWrap';
import type { ExperimentProps } from './GiftWrapSection.types';
import { twMerge } from 'tailwind-merge';

export const ExperimentA: React.FC<ExperimentProps> = ({
  isGiftWrapListLoading = false,
  giftWrappingDisclaimer = 'Only gifts and non-flower products will be wrapped',
  giftWrapWidgetTitle = 'Gift wrapping',
  giftWrapList = [],
  handleOnSelectWrapClick = () => null,
  selectedItem,
  isArabic = false,
  item,
  perViewSlides = 4.5,
  giftWrappingDisclaimerClass = '',
}) => {
  if (!item?.isEligibleForGiftWrapping) {
    return null;
  }
  if (isGiftWrapListLoading) {
    return (
      <div
        data-testid='TestId__LoadingContainer'
        className='fui-relative -fui-mt-3 fui-rounded-bl-xl fui-rounded-br-xl fui-bg-white fui-px-4 fui-pb-4 fui-pt-10px'
      >
        <LoadingSkeleton />
      </div>
    );
  }
  if (!giftWrapList?.length) {
    return null;
  }
  return (
    <div className='fui-relative -fui-mt-3 fui-rounded fui-bg-white fui-p-4 fui-pt-3'>
      <hr className='fui-my-1 fui-h-px fui-border-t fui-border-t-gray-330' />
      <div className='fui-my-3 fui-flex fui-items-center fui-gap-7px'>
        <IconGiftWrap className='fui-h-6 fui-w-6' />
        <h3 className='fui-text-md fui-font-bold fui-text-black-125'>
          {giftWrapWidgetTitle}
        </h3>
      </div>
      <GiftWrapList
        giftWrapList={giftWrapList}
        onSelectGiftWrap={(giftWrapItem: any, idx: number) =>
          handleOnSelectWrapClick(item, giftWrapItem, idx)
        }
        className={'ltr:-fui-ml-2 rtl:-fui-mr-2'}
        selectedItem={selectedItem}
        isArabic={isArabic}
        perViewSlides={perViewSlides}
      />
      {item?.type === ProductType.Bundle && (
        <div
          className={twMerge(
            'fui-mt-4 fui-flex fui-items-center fui-gap-8px fui-text-md fui-font-medium fui-text-theme-amber-900',
            giftWrappingDisclaimerClass
          )}
        >
          <IconWarning className=' fui-inline-block fui-h-4 fui-w-4' />
          {giftWrappingDisclaimer}
        </div>
      )}
    </div>
  );
};
