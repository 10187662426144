import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { BasketItemProps } from './BasketItemsList.types';

export const BasketItem: React.FC<BasketItemProps> = ({
  className = '',
  basketItemImg,
  basketItemName = '',
}) => {
  return (
    <div
      className={twMerge('fui-block fui-text-center', className)}
      data-testid='TestId__BasketItem'
    >
      <div
        className={
          'fui-rounded-[0.25rem] fui-border-2 fui-border-transparent fui-p-1'
        }
        data-testid='TestId__BasketItemImgContainer'
      >
        <div className='fui-relative fui-mb-2'>
          {basketItemImg ? (
            <img
              data-testid='TestId__BasketItemImg'
              src={basketItemImg}
              className={
                'fui-mb-2 fui-aspect-square fui-rounded-[0.25rem] fui-object-cover'
              }
              alt='basket-item'
            />
          ) : null}
          {basketItemName ? (
            <p
              data-testid='TestId__BasketItemName'
              className=' fui-line-clamp-2 fui-overflow-hidden fui-text-sm fui-font-medium fui-leading-[16px] fui-text-black-900 ltr:fui-text-left rtl:fui-text-right'
            >
              {basketItemName}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
