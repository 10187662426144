import React, { useState, Fragment, useEffect } from 'react';
import cx from 'clsx';
import { useKeenSlider } from 'keen-slider/react.js';
import type { KeenSliderOptions } from 'keen-slider';
import IconArrowLeft from '@flowardco/fui-icons/src/IconArrowLeft';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import { ArrowButton } from './ArrowButton';

export interface CarouselProps {
  className?: string;
  wrapperClassName?: string;
  carouselOptions?: KeenSliderOptions;
  showArrows?: boolean;
  showBullets?: boolean;
  leftArrowIcon?: React.ReactNode;
  rightArrowIcon?: React.ReactNode;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  activeBulletPointColor?: string;
  inactiveBulletPointColor?: string;
  bulletsContainerClassName?: string;
  bulletPointsClassName?: string;
  onNextButtonClick?: () => void;
  onPrevButtonClick?: () => void;
  onPagingButtonClick?: (index: number) => void;
  onSlideChanged?: (index: number, maxIdx?: number) => void;
  thumbnailList?: any[];
  showThmbnail?: boolean;
  currentActiveSlide?: number;
  onLoaded?: (val?: boolean) => void;
  children?: React.ReactNode;
  theaterMode?: boolean;
}

export const Carousel: React.FC<CarouselProps> = ({
  children = null,
  className = '',
  wrapperClassName = '',
  leftArrowClassName = '',
  rightArrowClassName = '',
  bulletsContainerClassName = '',
  bulletPointsClassName = '',
  activeBulletPointColor = 'fui-bg-white',
  inactiveBulletPointColor = 'fui-bg-gray-700',
  showArrows = false,
  showBullets = false,
  thumbnailList = [],
  showThmbnail = false,
  leftArrowIcon = (
    <IconArrowLeft
      className={cx('fui-w-4 fui-text-white', leftArrowClassName)}
    />
  ),
  rightArrowIcon = (
    <IconArrowRight
      className={cx('fui-w-4 fui-text-white', rightArrowClassName)}
    />
  ),
  carouselOptions = {
    loop: true,
  },
  currentActiveSlide = 0,
  onNextButtonClick,
  onPrevButtonClick,
  onPagingButtonClick,
  onSlideChanged,
  onLoaded = () => null,
  theaterMode,
}) => {
  const [currentSlide, setCurrentSlide] = useState(currentActiveSlide);
  const [loaded, setLoaded] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [refCallback, instanceRef] = useKeenSlider<HTMLDivElement>({
    ...carouselOptions,
    slideChanged(slider) {
      setCurrentSlide(slider?.track?.details?.rel || 0);
      setActiveIndex(slider?.track?.details?.rel || 0);
      if (onSlideChanged) {
        onSlideChanged(
          slider?.track?.details?.rel,
          instanceRef?.current?.track?.details?.maxIdx
        );
      }

      if (theaterMode) {
        const rel = slider?.track?.details?.rel;
        const activeSlide = slider.slides[rel];
        const activeSlideChild = activeSlide.firstElementChild as HTMLElement;
        activeSlide.style.opacity = '1';
        activeSlideChild.style.height = '100%';

        if (rel - 1 >= 0) {
          const previousSlide = slider.slides[rel - 1];
          const previousSlideChild =
            previousSlide.firstElementChild as HTMLElement;
          previousSlide.style.opacity = '0.4';
          previousSlideChild.style.height = '90%';
          previousSlide.style.display = 'flex';
          previousSlide.style.alignItems = 'center';
        }

        if (rel + 1 <= slider.slides.length - 1) {
          const nextSlide = slider.slides[rel + 1];
          const nextSlideChild = nextSlide.firstElementChild as HTMLElement;
          nextSlide.style.opacity = '0.4';
          nextSlideChild.style.height = '90%';
          nextSlide.style.display = 'flex';
          nextSlide.style.alignItems = 'center';
        }
      }
    },
    created(slider) {
      setLoaded(true);
      onLoaded(true);

      if (theaterMode && slider.slides.length > 0) {
        const previousSlide = slider.slides[0];
        const previousSlideChild =
          previousSlide.firstElementChild as HTMLElement;
        const nextSlide = slider.slides[2];
        const nextSlideChild = nextSlide.firstElementChild as HTMLElement;

        previousSlide.style.opacity = '0.4';
        nextSlideChild.style.height = '90%';
        nextSlide.style.display = 'flex';
        nextSlide.style.alignItems = 'center';

        if (nextSlide) {
          nextSlide.style.opacity = '0.4';
          previousSlideChild.style.height = '90%';
          previousSlide.style.display = 'flex';
          previousSlide.style.alignItems = 'center';
        }
      }
    },
  });
  const totalSlides = instanceRef?.current?.track?.details?.slides?.length || 0;
  const slidesPerView =
    (instanceRef?.current?.options?.slides as any)?.perView || 1;
  const slidesPerViewN = slidesPerView === 'auto' ? 1 : slidesPerView;
  const totalBullets = Math.ceil(totalSlides - slidesPerViewN + 1);

  const handleNextButtonClick = (e: any) => {
    e.stopPropagation();
    instanceRef.current?.next();
    if (onNextButtonClick) {
      onNextButtonClick();
    }
  };
  const handlePrevButtonClick = (e: any) => {
    e.stopPropagation();
    instanceRef.current?.prev();
    if (onPrevButtonClick) {
      onPrevButtonClick();
    }
  };
  const handleClickOnThumbnail = (index: number) => {
    instanceRef.current?.moveToIdx(index);
    setActiveIndex(index);
  };
  const handlePaginationButtonClick = (idx: number) => {
    instanceRef.current?.moveToIdx(idx);
    if (onPagingButtonClick) {
      onPagingButtonClick(idx);
    }
  };
  const displayBullets =
    (showBullets &&
      loaded &&
      instanceRef?.current &&
      instanceRef?.current?.track?.details?.slides?.length > 1) ||
    false;
  useEffect(() => {
    if (instanceRef.current?.moveToIdx) {
      instanceRef.current?.moveToIdx(currentActiveSlide);
    }
  }, [currentActiveSlide]);
  return (
    <div
      className={cx('fui-relative fui-block fui-w-full', wrapperClassName)}
      data-testid='TestId__CarouselWrapper'
    >
      {showThmbnail && (
        <ul className='thumbnail-list fui-hidden fui-w-19 ltr:fui-mr-4 rtl:fui-ml-4 md:fui-block'>
          {thumbnailList.map((item, index) => (
            <li
              key={index}
              onClick={() => handleClickOnThumbnail(index)}
              className={cx(
                'fui-mb-2 fui-cursor-pointer fui-border',
                activeIndex === index ? 'fui-border-emerald-400' : ''
              )}
            >
              <img
                src={item}
                className='fui-h-auto fui-max-w-full fui-object-cover'
                alt=''
                width={'75'}
                height={'75'}
              />
            </li>
          ))}
        </ul>
      )}
      <div
        ref={refCallback}
        className={cx('keen-slider', className)}
        data-testid='TestId__Carousel'
        data-active-slide={currentSlide}
      >
        {children}
      </div>
      {showArrows && loaded && instanceRef.current && totalBullets > 1 && (
        <Fragment>
          <ArrowButton
            icon={leftArrowIcon}
            onClick={handlePrevButtonClick}
            leftArrowClassName={leftArrowClassName}
            isLeft
            disabled={!carouselOptions.loop && currentSlide === 0}
          />
          <ArrowButton
            icon={rightArrowIcon}
            onClick={handleNextButtonClick}
            rightArrowClassName={rightArrowClassName}
            isRight
            disabled={
              !carouselOptions.loop &&
              currentSlide === totalSlides - slidesPerViewN
            }
          />
        </Fragment>
      )}
      {displayBullets && totalBullets > 1 && (
        <div
          className={cx(
            'fui-align-center fui-absolute fui-bottom-0 fui-z-10 fui-flex fui-w-full fui-justify-center',
            bulletsContainerClassName
          )}
        >
          {Array.from(Array(totalBullets).keys()).map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => handlePaginationButtonClick(idx)}
                className={cx(
                  'fui-m-1 fui-inline-block fui-h-2 fui-w-2 fui-rounded-full fui-bg-opacity-50',
                  currentSlide === idx
                    ? activeBulletPointColor
                    : inactiveBulletPointColor,
                  bulletPointsClassName
                )}
                data-testid='TestId__CarouselPaginationButton'
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
