import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { TotalSummaryProps } from './TotalSummary.types';

export const TotalSummary: React.FC<TotalSummaryProps> = ({
  className = '',
  summaryListItems,
  totalData,
}) => {
  return (
    <div className={twMerge(className, '')} data-testid='TestId__TotalSummary'>
      <div className='fui-border-b fui-border-gray-300'>
        {summaryListItems?.map((item) => (
          <div
            data-testid='TestId__SummaryDetails'
            key={`summary-details-${item.id}`}
            className='fui-mb-3 fui-flex fui-w-full fui-flex-wrap fui-items-center fui-justify-between'
          >
            <div className='fui-flex fui-w-full fui-flex-wrap fui-items-center fui-justify-between'>
              <div className='fui-block fui-pb-1 fui-text-md fui-font-medium'>
                {item.title}
              </div>
              <div
                style={{ color: item.color || '' }}
                className='fui-block fui-p-2 fui-pb-1 fui-text-md fui-font-medium fui-text-black-600'
              >
                <span>{item.value}</span>
              </div>
              {item?.subTitle ? (
                <span
                  className='fui-block fui-py-1 fui-text-sm'
                  data-testid='TestId__SummaryDetailsSubTitle'
                >
                  {item?.subTitle}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div
          data-testid='TestId__SummaryDetailsTotal'
          className='fui-mt-3 fui-flex fui-w-full fui-flex-wrap fui-items-center fui-justify-between'
        >
          <div className='fui-block fui-pb-1 fui-text-md fui-font-bold'>
            {totalData?.title}
          </div>
          <div
            style={{ color: totalData?.color || '' }}
            className='fui-block fui-p-2 fui-pb-1 fui-text-md fui-font-bold fui-text-black-600'
          >
            <span>{totalData?.value}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
