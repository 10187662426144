import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';

import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';
import {
  formatDeliveryDatesResponse,
  formatDeliverySlotResponse,
} from '@flowardco/app-shared/helpers/utilities';
import { addRequestHeaders, client, orderClient } from './api.service';
import { addToast, convertDateToYMDTime } from '@flowardco/flib-util';

export const getOrderPaymentToken = async (token: string, lang: string) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderPaymentToken, {
        token,
      }),
      {
        headers,
      }
    );
    const orderPaymentToken = formatHttpResponse(res);
    return orderPaymentToken;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderPaymentToken', error);
    return null;
  }
};

export const getOrderDetails = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderDetails', error);
    return null;
  }
};

export const getOrderAddressDetails = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderAddressDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderAddressDetails', error);
    return null;
  }
};

export const getOrderCartItemsDetails = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderCartItemsDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderCartItemsDetails', error);
    return [];
  }
};

export const getOrderCardDetails = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderCardDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderCardDetails', error);
    return [];
  }
};

export const getOrderPaymentList = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderPaymentList, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderPaymentList', error);
    return [];
  }
};

export const getOrderDeliveryDates = async ({
  lang,
  orderId,
  __T,
  __TK,
}: {
  lang: string;
  orderId: number;
  __T: any;
  __TK: any;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderDeliveryDates, {
        orderId,
      }),
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return formatDeliveryDatesResponse({
      data: orderDetailsData || [],
      __T,
      __TK,
    });
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderDeliveryDates', error);
    return [];
  }
};

export const getOrderDeliverySlots = async ({
  orderId,
  date,
  orderToken,
  __T,
}: any) => {
  try {
    const unixDate = convertDateToYMDTime(new Date(date));
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderDeliverySlots, {
        date: unixDate,
        orderId,
      }),
      {
        headers: {
          Uniqid: orderToken || '',
        },
      }
    );
    const slots = formatHttpResponse(res);
    return formatDeliverySlotResponse({ data: slots || {}, __T });
  } catch (error: any) {
    errorLoggerV2('OrderService::getOrderDeliverySlots', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const updateOrderDeliverySlotandDates = async ({
  lang,
  deliveryDate,
  orderToken,
  deliverySlot,
}: {
  lang: string;
  deliveryDate: string;
  orderToken: string;
  deliverySlot: string | number;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const unixDate = convertDateToYMDTime(new Date(deliveryDate));
    const res = await orderClient.post(
      formatUrlParams(API_ENDPOINTS.secureCheckout.updateOrderDeliverySlot, {
        orderToken,
      }),
      { deliveryDate: unixDate, deliverySlot },
      {
        headers,
      }
    );
    const orderDetailsData = formatHttpResponse(res);
    return orderDetailsData;
  } catch (error: any) {
    errorLoggerV2('OrderService::updateOrderDeliverySlotandDates', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const deleteCardMessage = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await orderClient.delete(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderCardDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const data = formatHttpResponse(res);
    return data;
  } catch (error: any) {
    errorLoggerV2('OrderService::deleteCardMessage', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const updateCardMessage = async ({
  lang,
  orderToken,
  cardData,
}: {
  lang: string;
  orderToken: string;
  cardData: any;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await orderClient.post(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getOrderCardDetails, {
        orderToken,
      }),
      cardData,
      {
        headers,
      }
    );
    const data = formatHttpResponse(res);
    return data;
  } catch (error: any) {
    errorLoggerV2('OrderService::updateCardMessage', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const placeOrder = async ({
  lang,
  orderId,
  paymentData,
}: {
  lang: string;
  orderId: number | string;
  paymentData: any;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await orderClient.post(
      formatUrlParams(API_ENDPOINTS.secureCheckout.placeOrder, {
        orderId,
      }),
      paymentData,
      {
        headers,
      }
    );
    const data = formatHttpResponse(res);
    return data;
  } catch (error: any) {
    errorLoggerV2('OrderService::placeOrder', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};

export const getMyOrderDetails = async ({
  lang,
  orderToken,
}: {
  lang: string;
  orderToken: string;
}) => {
  try {
    const headers = addRequestHeaders({
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.secureCheckout.getMyOrderDetails, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const data = formatHttpResponse(res);
    return data;
  } catch (error: any) {
    errorLoggerV2('OrderService::getMyOrderDetails', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    return null;
  }
};
