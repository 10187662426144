import React from 'react';
import cx from 'clsx';
import { Button } from '@flowardco/fui-button';
export interface AddressCardProps {
  onDelete?(e: React.MouseEvent): void;
  onEdit?(e: React.MouseEvent): void;
  onAddressSelect?(e: React.MouseEvent, id: number): void;
  className?: string;
  postalCode: string;
  name?: string;
  addressLine?: string;
  recipientName: string;
  recipientPhone: string;
  isEditMode?: boolean;
  isSelected?: boolean;
  nameLabel?: string;
  phoneLabel?: string;
  deleteLabel?: string;
  editLabel?: string;
  iconUrl?: string | null;
  id?: number;
}

export const AddressCard: React.FC<AddressCardProps> = ({
  className,
  id = -1,
  name,
  addressLine,
  recipientName,
  recipientPhone,
  isEditMode = true,
  isSelected = false,
  deleteLabel = 'Delete',
  editLabel = 'Edit',
  nameLabel = 'Name',
  phoneLabel = 'Phone',
  iconUrl = 'https://cdn.zeplin.io/5f180e07111b776cefe98a2f/assets/84E82D45-0B50-458D-ADEA-F1943AED6684.png',
  onDelete,
  onEdit,
  onAddressSelect,
}) => {
  const onCardClick = (e: React.MouseEvent) => {
    if (isEditMode) return;

    if (onAddressSelect) {
      onAddressSelect(e, id);
    }
  };
  return (
    <div
      className={cx(
        'fui-relative fui-mb-4 fui-flex fui-w-full fui-border fui-border-solid fui-p-4',
        {
          'fui-cursor-pointer': !isEditMode,
          '!fui-border-navy-500': isSelected && !isEditMode,
          '!fui-border-gray-500': !isSelected || isEditMode,
        },
        className
      )}
      onClick={onCardClick}
      data-testid='TestId__AddressCard'
    >
      <div className='fui-w-80 fui-flex-1'>
        {name ? (
          <>
            <p data-testid='TestId__PostalCodeP'>
              {iconUrl ? (
                <img
                  data-testid='TestId__Icon'
                  src={iconUrl}
                  className='fui-mr-2 fui-inline fui-h-18px fui-w-18px'
                  alt=''
                />
              ) : (
                ''
              )}
              <span className='fui-break-all fui-font-medium fui-text-black-300'>
                {name}
              </span>
            </p>
          </>
        ) : null}
        {addressLine ? (
          <p
            className='fui-break-all fui-text-[80%]'
            data-testid='TestId__AddressLineP'
          >
            {addressLine}
          </p>
        ) : null}
        <p className='fui-break-all fui-text-[80%]' data-testid='TestId__NameP'>
          {nameLabel}: {recipientName}
        </p>

        <p className='fui-text-[80%]' data-testid='TestId__PhoneP'>
          {phoneLabel}: {recipientPhone}
        </p>
      </div>
      {isEditMode ? (
        <div className='fui-20 fui-flex-1 fui-text-center'>
          <Button
            buttonStyle={'basicUnderline'}
            testid='TestId__DeleteBtn'
            label={deleteLabel}
            size={'base'}
            onClick={onDelete}
          />
          <Button
            buttonStyle={'primaryUnderline'}
            testid='TestId__EditBtn'
            label={editLabel}
            size={'base'}
            onClick={onEdit}
          />
        </div>
      ) : null}
    </div>
  );
};
