import * as React from 'react';
import type { SVGProps } from 'react';
import { type Ref, forwardRef } from 'react';
const SvgIconReceipt = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 19'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M13.75 10.167h-7.5a.246.246 0 0 0-.25.25v1c0 .156.094.25.25.25h7.5c.125 0 .25-.094.25-.25v-1a.27.27 0 0 0-.25-.25m1.75-8.813-1.344.969-1.219-.844a1.5 1.5 0 0 0-1.75 0L10 2.323l-1.219-.844a1.46 1.46 0 0 0-.875-.281c-.344 0-.625.094-.875.281l-1.219.844-1.343-.969C3.844.917 3 1.354 3 2.104V16.26c0 .72.844 1.188 1.469.75l1.343-1 1.22.875a1.5 1.5 0 0 0 1.75 0L10 16.01l1.188.875a1.5 1.5 0 0 0 1.75 0l1.218-.875 1.344 1c.625.438 1.5 0 1.5-.75V2.104c0-.75-.875-1.187-1.5-.75m0 13.781-1.344-.968-2.094 1.5-2.093-1.5-2.063 1.5-2.094-1.5-1.312.968V3.23l1.312.969 2.094-1.5L10 4.198l2.063-1.5 2.093 1.5 1.344-.969zm-1.75-8.468h-7.5a.246.246 0 0 0-.25.25v1c0 .156.094.25.25.25h7.5c.125 0 .25-.094.25-.25v-1a.27.27 0 0 0-.25-.25'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconReceipt);
export default ForwardRef;
