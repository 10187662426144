import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { AppRoutes } from '@/routes/AppRoutes';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import { ToastComponent } from '@flowardco/fui-toast-component';
import { traceClientLogs } from '@flowardco/app-shared/helpers/ddClientLogs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { LocalAppContextProvider } from './Context/LocalAppContext';

traceClientLogs('React-SecureCheckout');

const queryClient = new QueryClient();
export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <Suspense fallback={<FullPageLoader />}>
          <HelmetProvider>
            <LocalAppContextProvider>
              <AppRoutes />
            </LocalAppContextProvider>
          </HelmetProvider>
        </Suspense>
        <ToastComponent />
      </AppErrorBoundary>
    </QueryClientProvider>
  );
};
