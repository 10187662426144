import React from 'react';
import cx from 'clsx';

export const CarouselSlide: React.FC<{
  className?: string;
  children?: React.ReactNode;
}> = ({ className = '', children }) => {
  return (
    <div
      data-testid='TestId__CarouselSlide'
      className={cx('keen-slider__slide', className)}
    >
      {children}
    </div>
  );
};
