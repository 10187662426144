import React, { useState, type Dispatch, type SetStateAction } from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconPen from '@flowardco/fui-icons/src/IconPen';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { type PaymentDataProps } from './PaymentOptionsList';
import { SenderNotesModal } from './SenderNotesModal';

export const SenderNotes = ({
  paymentDataState,
  setPaymentDataState,
}: {
  paymentDataState: PaymentDataProps;
  setPaymentDataState: Dispatch<SetStateAction<PaymentDataProps>>;
}) => {
  const { __T } = useLocalTranslator();
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className='fui-block fui-pb-2 fui-pt-3'>
      <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
        <span>
          <IconHeading title={__T('Note to Floward')} icon={<IconPen />} />
        </span>
        <span>
          <button
            className='fui-text-sm fui-font-medium fui-text-theme-navy-900'
            type='button'
            onClick={toggleModal}
          >
            {__T(paymentDataState?.noteForFloward ? 'Change' : 'Add')}
          </button>
        </span>
      </div>
      <div className='fui-min-h-[5rem] fui-rounded-md fui-border fui-border-gray-250 fui-p-3 fui-text-md fui-text-theme-neutral-500'>
        {paymentDataState?.noteForFloward || (
          <span
            className='fui-text-theme-neutral-400'
            dangerouslySetInnerHTML={{
              __html: __T(
                'Write your notes for the Floward team,<br/>eg: deliver before 11 AM'
              ),
            }}
          />
        )}
      </div>
      {showModal ? (
        <SenderNotesModal
          paymentDataState={paymentDataState}
          setPaymentDataState={setPaymentDataState}
          onCloseModal={toggleModal}
        />
      ) : (
        ''
      )}
    </div>
  );
};
