import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconTrashAlt = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    ref={ref}
    {...props}
  >
    <g id='Delete - 1 1'>
      <path
        id='Vector'
        d='M16.5199 8.66251L16.0059 15.6989C15.8997 17.1544 14.6877 18.2822 13.2273 18.2822H8.19315C6.73357 18.2822 5.52079 17.1544 5.41456 15.6981L4.90051 8.66251'
        stroke='currentColor'
        strokeWidth='1.44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M17.6525 6.02109H3.7677'
        stroke='currentColor'
        strokeWidth='1.44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_3'
        d='M13.878 6.02017L13.4555 3.94032C13.3329 3.48184 12.9169 3.16312 12.4429 3.16312H8.98111C8.50466 3.16149 8.08705 3.48021 7.96365 3.94032L7.54523 6.02017'
        stroke='currentColor'
        strokeWidth='1.44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgIconTrashAlt);
export default ForwardRef;
