import * as React from 'react';
import type { SVGProps } from 'react';
import { type Ref, forwardRef } from 'react';
const SvgIconEnvelopeOpen = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 19'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M17.438 6.323c-.157-.125-3.5-2.813-4.22-3.406-.687-.532-1.874-1.75-3.218-1.75-1.375 0-2.531 1.187-3.25 1.75a467 467 0 0 1-4.219 3.406A1.46 1.46 0 0 0 2 7.479v8.188c0 .843.656 1.5 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5V7.479c0-.469-.219-.875-.562-1.156m-.938 9.156c0 .125-.094.188-.187.188H3.688q-.188 0-.188-.188V7.573c0-.063 0-.125.063-.156.5-.407 3.406-2.72 4.125-3.313C8.25 3.635 9.25 2.667 10 2.667c.719 0 1.719 1 2.281 1.437.719.594 3.625 2.906 4.125 3.313a.17.17 0 0 1 .094.156zm-1-5.844-.5-.593c-.125-.157-.344-.188-.531-.032a86 86 0 0 1-2.188 1.75c-.562.47-1.562 1.407-2.281 1.407-.75 0-1.75-.97-2.312-1.407A86 86 0 0 1 5.5 9.01c-.187-.156-.406-.125-.531.032l-.5.593a.39.39 0 0 0 .062.532c.907.718 1.875 1.5 2.219 1.78.688.532 1.875 1.72 3.25 1.72 1.344 0 2.5-1.157 3.219-1.72.344-.312 1.312-1.062 2.219-1.78a.39.39 0 0 0 .062-.532'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconEnvelopeOpen);
export default ForwardRef;
