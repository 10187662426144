import React, {
  Fragment,
  useState,
  type Dispatch,
  type SetStateAction,
  type FormEvent,
} from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconPaymentCard from '@flowardco/fui-icons/src/IconPaymentCard';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { PaymentMethodsEnum } from '@flowardco/flib-models';
import {
  CreditCardForm,
  CreditCardFormUi,
} from '@flowardco/fui-credit-card-form';
import { useParams } from 'react-router';
import { PaymentList } from '@flowardco/fui-payment-list';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

export interface PaymentDataProps {
  paidFromWallet: boolean;
  saveCard: boolean;
  paymentType: number | undefined;
  paymentVendor: number;
  autoRenew: boolean;
  callbackUrl: string;
  cardID: number;
  tapToken: string;
  cardDetails: string;
  CardBin: string;
  title: string;
  fromSavedCard?: boolean;
  savedCardId?: string;
  noteForFloward?: string;
}

export const PaymentOptionsList = ({
  paymentList = [],
  orderData = {},
  isAppLoading = true,
  paymentDataState,
  setPaymentDataState,
  onPaymentSelect,
  onPaymentSubmit,
}: {
  paymentList: any;
  orderData?: any;
  isAppLoading?: boolean;
  paymentDataState: PaymentDataProps;
  setPaymentDataState: Dispatch<SetStateAction<PaymentDataProps>>;
  onPaymentSelect: Dispatch<SetStateAction<PaymentDataProps>>;
  onPaymentSubmit: (e: FormEvent) => void;
}) => {
  const formattedPaymentList = paymentList?.map((item: any) => ({
    ...item,
    isCredit: item?.paymentVendor === 2 && !item?.savedCard,
    isRenderable: true,
  }));
  const { paymentToken } = useParams();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [isCreditCardState, setIsCreditCardState] = useState(false);

  const { __T } = useLocalTranslator();
  const availablePayments = formattedPaymentList
    ?.map((itm: any) => {
      return {
        ...itm,
        isRenderable: ![
          PaymentMethodsEnum.ApplePay,
          PaymentMethodsEnum.GooglePay,
        ].includes(itm?.id),
        cardForm:
          (selectedPaymentMethod?.id === PaymentMethodsEnum.CreditCard ||
            selectedPaymentMethod?.id === PaymentMethodsEnum.Mada) &&
          itm?.id === selectedPaymentMethod?.id &&
          isCreditCardState &&
          !itm.savedCard ? (
            <AppErrorBoundary>
              <CreditCardFormUi
                key='creditCardForm'
                saveCard={paymentDataState?.saveCard}
                __T={__T}
                onChangeSavedCard={() => null}
                checkoutKey={orderData?.operation?.checkoutPk}
                isEncrypted={false}
                showSaveCardOption={false}
              />
            </AppErrorBoundary>
          ) : (
            ''
          ),
      };
    })
    .filter((itm: any) => itm.isRenderable);

  const onSelectPaymentMethod = (paymentMethod: any) => {
    setIsCreditCardState(
      paymentMethod?.isCredit &&
        (paymentMethod?.id === PaymentMethodsEnum.CreditCard ||
          paymentMethod?.id === PaymentMethodsEnum.Mada)
    );
    setSelectedPaymentMethod(paymentMethod);
    setPaymentDataState({
      ...paymentDataState,
      paymentType: paymentMethod?.id,
      savedCardId: paymentMethod?.savedCardId,
      paymentVendor: paymentMethod?.paymentVendor,
      fromSavedCard: paymentMethod?.savedCard,
      title: paymentMethod?.title,
    });
    onPaymentSelect(paymentMethod);
  };
  if (
    !paymentToken ||
    !orderData?.operation?.checkoutPk ||
    !availablePayments?.length
  ) {
    return <Fragment />;
  }

  return (
    <div className='fui-block fui-pb-2 fui-pt-3'>
      <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
        <IconHeading
          title={__T('Payment options')}
          icon={<IconPaymentCard />}
        />
      </div>
      <div className='-fui-mx-1 fui-block fui-py-2'>
        <AppErrorBoundary>
          <form
            id='checkoutPaymentForm'
            name='checkoutPaymentForm'
            onSubmit={onPaymentSubmit}
          >
            {!isAppLoading && availablePayments?.length > 0 ? (
              <PaymentList
                paymentList={availablePayments}
                onChange={onSelectPaymentMethod}
                onDelete={() => null}
                className='fui-relative fui-mb-5'
                __T={__T}
              />
            ) : (
              ''
            )}
          </form>
        </AppErrorBoundary>

        <AppErrorBoundary>
          {isCreditCardState ? (
            <CreditCardForm
              __T={__T}
              checkoutKey={orderData?.operation?.checkoutPk}
              isEncrypted={false}
            />
          ) : (
            ''
          )}
        </AppErrorBoundary>
      </div>
    </div>
  );
};
