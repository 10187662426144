import * as React from 'react';
import type { SVGProps } from 'react';
import { type Ref, forwardRef } from 'react';
const SvgIconPen = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 19'
    ref={ref}
    {...props}
  >
    <path
      fill='currentColor'
      d='M17.406 2.948 16.22 1.76a2.04 2.04 0 0 0-1.407-.593c-.5 0-1.03.218-1.406.593l-2.344 2.344L10 5.167l-7.625 7.625L2 16.354a.715.715 0 0 0 .719.813h.094l3.562-.375L14 9.167l1.063-1.063 2.343-2.344a1.98 1.98 0 0 0 0-2.812M5.688 15.354l-2.094.219.219-2.094 7.218-7.25L12 5.26l1.906 1.907-.969.968zM16.344 4.698l-1.375 1.406-1.906-1.906 1.406-1.375a.53.53 0 0 1 .344-.156c.062 0 .218.03.343.156l1.188 1.187a.49.49 0 0 1 0 .688'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconPen);
export default ForwardRef;
