import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconLogoX = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
    viewBox='0 0 87 36'
    fill='none'
  >
    <path
      d='M77.8913 1.13683L76.2488 0L75.0884 1.61166L76.7542 2.73936L77.8913 1.13683Z'
      fill='currentColor'
    />
    <path
      d='M81.7283 8.62388L81.733 8.61932L81.7189 8.5417L81.7142 8.48691L81.7096 8.48235C81.3352 6.27716 80.0531 3.73413 77.6433 3.73413C76.4875 3.73413 75.5469 4.39614 74.9199 5.64711C74.3771 6.73372 74.2274 7.93904 74.2227 8.47778C74.2227 10.2675 75.3223 11.4272 77.0303 11.4272C78.4809 11.4272 80.3245 10.8062 81.368 9.08044C81.4428 10.3816 81.1387 11.4272 80.4602 12.1987C79.6273 13.1393 78.2282 13.6552 76.4126 13.6826C76.3097 13.6826 37.6027 13.6871 37.3406 13.6826C33.499 13.6643 33.1948 11.2674 33.1948 8.13993V1.18652H32.783V7.22224L32.7737 7.37291C32.6286 10.1351 32.4274 13.92 25.46 14.6505L25.3757 14.6596L25.0669 16.285L25.212 16.2804C30.9581 16.0704 32.4976 13.2351 32.8953 10.5962C33.2603 14.1437 34.5565 15.5088 37.3406 15.5088C37.729 15.5088 76.305 15.5088 76.3611 15.5088C78.2375 15.4814 79.6554 14.9427 80.5772 13.9108C81.6113 12.7466 81.9997 10.9706 81.7283 8.62388ZM77.063 9.66484C75.706 9.66484 74.6906 8.85216 74.6906 8.12623C74.6906 7.39117 75.5937 5.55123 77.7275 5.55123C79.5618 5.55123 80.8673 7.2816 81.2884 8.423C80.3526 9.10783 78.4855 9.66484 77.063 9.66484Z'
      fill='currentColor'
    />
    <path
      d='M25.8485 4.48426C27.3132 5.86763 30.7384 9.10464 31.0566 11.5655L31.0706 11.6659H31.445L31.4309 11.5427C31.1455 8.72113 29.4142 5.66218 26.2884 2.43887L26.2556 2.40234H25.9936L25.2402 3.88616L25.2917 3.94552C25.3947 4.05509 25.5865 4.23315 25.8485 4.48426Z'
      fill='currentColor'
    />
    <path
      d='M2.45662 12.5465C5.54026 12.5465 7.64126 11.6425 8.34783 9.95321C9.24625 7.81194 7.70677 4.85344 4.43128 2.41541L4.4032 2.39258H4.10841L3.37844 3.93575L3.45799 3.99054C7.78632 6.94448 8.35719 8.50135 8.07175 9.28663C7.44473 11.017 2.49873 10.8663 0.397739 10.7248L0.290115 10.7157L0 12.3775L0.121661 12.3912C0.949893 12.4962 1.73133 12.5465 2.45662 12.5465Z'
      fill='currentColor'
    />
    <path
      d='M14.4035 8.46987C14.2491 5.32417 11.7176 3.25139 11.6895 3.22857L11.6567 3.20117H11.3947L10.5478 4.76717L10.632 4.82652C10.6647 4.84935 13.7905 6.95866 13.9823 8.51553C14.2023 10.3098 11.0765 13.0355 5.40055 15.9894L4.98877 16.204H8.83514L8.86321 16.1857C12.6768 13.8299 14.5392 11.2321 14.4035 8.46987Z'
      fill='currentColor'
    />
    <path
      d='M14.9413 15.9927L14.5342 16.2073H18.3291L18.3572 16.189C22.6434 13.5227 24.5572 10.5505 24.0425 7.36827C23.9582 6.83866 23.6775 5.47811 22.9007 4.28193C22.138 3.104 21.1132 2.47852 19.9481 2.47852C18.7923 2.47852 17.8518 3.14053 17.2248 4.3915C16.682 5.47811 16.5322 6.68343 16.5276 7.22217C16.5276 9.01189 17.6272 10.1715 19.3351 10.1715C20.7997 10.1715 22.6621 9.54149 23.6962 7.79744C23.7196 10.3131 21.1226 12.7237 14.9413 15.9927ZM16.9955 6.87062C16.9955 6.13556 17.8986 4.29562 20.0323 4.29562C21.8666 4.29562 23.1721 6.02598 23.5933 7.16738C22.6574 7.85679 20.7904 8.41379 19.3679 8.41379C18.0109 8.40923 16.9955 7.59655 16.9955 6.87062Z'
      fill='currentColor'
    />
    <path
      d='M66.0053 29.3788L65.6731 29.1642C65.4297 29.0044 65.1771 28.8538 64.9197 28.7031C63.8294 28.0548 62.7064 27.3882 62.5754 26.1418H64.784C66.5621 26.1418 68.3496 25.1191 68.3496 22.8363C68.3496 19.5765 65.032 19.4121 64.3675 19.4121H59.459V33.9353H60.9564V19.7911H63.6844C65.7947 19.7911 66.754 21.3662 66.754 22.8317C66.754 24.2425 65.8649 25.7583 63.9137 25.7583H62.1683V25.8724C62.1683 28.2054 63.6329 29.3058 65.0554 30.3741C66.2907 31.3009 67.4559 32.1775 67.4418 33.812V33.9261H69.0702V33.812C69.0749 31.3557 67.5167 30.3513 66.0053 29.3788Z'
      fill='currentColor'
    />
    <path
      d='M79.9968 21.8635C79.0516 20.7632 77.3624 19.4438 74.6812 19.4072H71.022V33.935H74.2647C79.1686 33.935 81.8124 30.1272 81.8124 26.5433C81.8124 24.9362 81.1339 23.1875 79.9968 21.8635ZM72.5193 19.7907H74.4285C76.7775 19.7907 80.0343 22.1101 80.0343 26.4565C80.0343 29.9446 77.9192 33.5514 74.377 33.5514H72.5193V19.7907Z'
      fill='currentColor'
    />
    <path
      d='M51.4531 19.4072V19.5214C51.4531 20.8454 51.2285 22.1512 50.784 23.4067L47.064 33.935H47.4945L49.179 29.068L49.2726 28.9676H54.4057L54.4947 29.0589L56.3383 33.8665L56.3664 33.9395H58.0135L52.2299 19.4164H51.4531V19.4072ZM54.3028 28.5886H49.3615L51.6543 22.1055L54.3028 28.5886Z'
      fill='currentColor'
    />
    <path
      d='M24.3882 19.3359C24.3742 19.3359 24.3648 19.3359 24.3508 19.3359C22.3761 19.3359 20.5138 20.0847 19.1053 21.4544C17.6734 22.8423 16.8826 24.6868 16.878 26.6592C16.8733 28.6224 17.6454 30.4623 19.0491 31.8457C20.4576 33.2291 22.334 33.9961 24.3367 34.0006C24.3414 34.0006 24.3508 34.0006 24.3554 34.0006C26.3395 34.0006 28.2112 33.2382 29.629 31.8548C31.0515 30.4669 31.8376 28.6269 31.8423 26.6774C31.8516 22.646 28.506 19.3542 24.3882 19.3359ZM27.1958 32.9505C26.1242 33.3021 24.9684 33.2884 23.7612 32.9095C22.4884 32.5077 21.3513 31.7635 20.3827 30.6951C19.2644 29.4624 18.5625 28.038 18.2911 26.4537C18.2443 26.1798 18.2256 25.9058 18.2069 25.6867C18.2022 25.6045 18.1975 25.5315 18.1881 25.4858C18.2069 23.897 18.5999 22.7145 19.4235 21.7603C20.2798 20.7695 21.3935 20.2262 22.7317 20.1486C22.8627 20.1395 22.9938 20.1395 23.1201 20.1395C24.6736 20.1395 26.1008 20.6919 27.4625 21.8196C28.7072 22.8469 29.6009 24.1526 30.1156 25.6958C30.5648 27.0427 30.635 28.2799 30.3215 29.4853C29.8817 31.2476 28.8241 32.4164 27.1958 32.9505Z'
      fill='currentColor'
    />
    <path
      d='M47.5974 19.4072L44.4763 31.4924L41.2242 19.494L41.2008 19.4072H40.0871V19.5214C40.0871 20.4482 39.9795 21.3796 39.769 22.279L37.6399 31.465L34.3036 19.494L34.2802 19.4118H32.605L36.676 33.8528L36.6993 33.935H37.8505V33.8208C37.8505 32.7707 37.9721 31.7161 38.2108 30.6888L40.2837 21.8133L43.4562 33.935H44.6775V33.8208C44.6775 32.7707 44.8132 31.7206 45.0753 30.7025L47.9904 19.4118H47.5974V19.4072Z'
      fill='currentColor'
    />
    <path
      d='M0 33.935H1.50205V25.4292C3.49542 25.4429 5.44668 25.8173 7.29967 26.5387L7.45877 26.6026V25.0092H1.50205V19.8318C3.49542 19.8455 5.44668 20.2199 7.29967 20.9413L7.45877 21.0006V19.4072H0V33.935Z'
      fill='currentColor'
    />
    <path
      d='M11.2818 33.5149V19.4072H9.77979V33.9304H17.2386V32.337L17.0795 32.4009C15.2218 33.1268 13.2752 33.5012 11.2818 33.5149Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconLogoX);
export default ForwardRef;
