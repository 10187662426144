import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { RouteError } from '@flowardco/app-shared/components/RouteError';
import RootLayoutPage from '@/pages/Root';
import LayoutPage from '@/pages/Root/Layout';
import SecurePaymentPage from '@/pages/SecurePayment';
import CheckoutConfirmationPage from '@/pages/CheckoutConfirmation';
import NotFoundPage from '@/components/NotFoundPage';

interface RoutesConfigItemProps {
  path: string;
  comp: any;
}
type RoutesConfigProps = RoutesConfigItemProps[];
const routes: RoutesConfigProps = [
  {
    path: ':paymentToken',
    comp: SecurePaymentPage,
  },
  {
    path: 'checkout-confirmation/:paymentToken',
    comp: CheckoutConfirmationPage,
  },
];
export const routesConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayoutPage />}>
      <Route
        id='root'
        path=':lang'
        element={<LayoutPage />}
        errorElement={<RouteError />}
        shouldRevalidate={() => false}
      >
        {routes?.map((route: RoutesConfigItemProps, i: number) => {
          return (
            <Route
              key={`route-${route.path}-${i}`}
              path={route?.path}
              element={<route.comp />}
              errorElement={<RouteError />}
            />
          );
        })}
        {/* Redirect all non matching request to website */}
        <Route path='*' element={<NotFoundPage />} />
      </Route>
      {/* Redirect all non matching request to website */}
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  )
);
