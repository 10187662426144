import React from 'react';
import { Button } from '@flowardco/ui/Button';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import IconGiftWrap from '@flowardco/fui-icons/src/IconGiftWrap';
import type { ExperimentProps } from './GiftWrapSection.types';

export const ExperimentB: React.FC<ExperimentProps> = ({
  giftWrapWidgetTitle = 'Gift wrapping',
  giftWrapList = [],
  handleOnSelectWrapClick = () => null,
  giftWrapBtnLabel = 'Select wrap',
  item,
}) => {
  if (!item?.isEligibleForGiftWrapping) {
    return null;
  }
  if (!giftWrapList?.length) {
    return null;
  }
  return (
    <div className='fui-relative -fui-mt-3 fui-rounded-bl-xl fui-rounded-br-xl fui-bg-white fui-px-4 fui-pb-4 fui-pt-10px'>
      <hr className='fui-my-1 fui-h-px fui-border-t fui-border-t-gray-330' />
      <div className='fui-mt-15px fui-flex fui-items-center fui-justify-between'>
        <div className='fui-flex fui-items-center fui-gap-7px'>
          <IconGiftWrap className='fui-h-6 fui-w-6' />
          <h3 className='fui-text-md fui-font-bold fui-text-black-125'>
            {giftWrapWidgetTitle}
          </h3>
        </div>

        <Button
          className='!fui-h-auto !fui-p-0 fui-text-md fui-font-medium fui-text-theme-navy-900'
          onClick={() => handleOnSelectWrapClick(item)}
          buttonStyle='basicText'
        >
          {giftWrapBtnLabel}
          <IconArrowRight className='fui-h-3 fui-w-3 ltr:fui-ml-2 rtl:fui-mr-2 rtl:fui-rotate-180' />
        </Button>
      </div>
    </div>
  );
};
