import React, { useEffect } from 'react';
import { Checkbox, FieldErrorMessage } from '@flowardco/fui-input-field';
import { CreditCardInput } from './CreditCardInput';
import { useCreditCard } from '@flowardco/flib-hooks';
import { crypto } from '@flowardco/flib-helpers';
import IconLock from '@flowardco/fui-icons/src/IconLock';
import { loadExternalScript } from '@flowardco/flib-util';
import cx from 'clsx';
declare global {
  interface Window {
    Frames: any;
  }
}
export interface CreditCardFormUiProps {
  className?: string;
  checkoutKey?: string;
  onCardTokenized?(cardData: any): void;
  onChangeSavedCard?(e: React.ChangeEvent<HTMLInputElement>): void;
  onCardTokenizationFailed?(event: any): void;
  __T: (key?: string) => string;
  saveCard?: boolean;
  isEncrypted?: boolean;
  showSaveCardOption?: boolean;
}
export const CreditCardFormUi: React.FC<CreditCardFormUiProps> = ({
  className = '',
  checkoutKey = '',
  onCardTokenized = () => null,
  onCardTokenizationFailed = () => null,
  saveCard = true,
  onChangeSavedCard,
  isEncrypted = true,
  showSaveCardOption = true,
  __T,
}) => {
  const { initFrames } = useCreditCard({
    __T,
    publicKey: isEncrypted ? crypto.decryptx(checkoutKey) : checkoutKey,
    onCardTokenized,
    onCardTokenizationFailed,
  });
  useEffect(() => {
    loadExternalScript(
      'https://cdn.checkout.com/js/framesv2.min.js',
      initFrames,
      'creditCardScript',
      true
    );
  }, []);
  const lockIcon = (
    <div
      data-testid='TestId__CreditCardIcon'
      className='fui-absolute fui-bottom-0 fui-right-16px fui-top-0 fui-flex fui-justify-center'
    >
      <IconLock className='fui-w-3' />
    </div>
  );
  return (
    <div className='fui-px-[7.5px]'>
      <div
        className={cx(
          className,
          'fui-mb-4 fui-w-full fui-bg-gray-50 fui-px-20px fui-py-10px md:fui-px-30px md:fui-py-20px'
        )}
        data-testid='TestId__CreditCardFormUi'
      >
        <div className='fui-px-[7.5px]'>
          <CreditCardInput
            name='card-number'
            icon={lockIcon}
            title={__T('Card number')}
          />
          <FieldErrorMessage className='error-message__card-number font-light fui-mx-8px fui-block fui-text-sm fui-text-error-750	' />
        </div>

        <div className='date-and-code fui-mb-8px fui-flex'>
          <div className='fui-w-1/2 fui-px-[7.5px]'>
            <CreditCardInput name='expiry-date' title={__T('Expiry date')} />
            <FieldErrorMessage className='error-message error-message__expiry-date font-light fui-mx-8px fui-block fui-text-sm fui-text-error-750	' />
          </div>

          <div className='fui-w-1/2 fui-px-[7.5px]'>
            <CreditCardInput
              name='cvv'
              icon={lockIcon}
              title={__T('Security code')}
            />
            <FieldErrorMessage className='error-message error-message__cvv  font-light fui-mx-8px fui-block fui-text-sm fui-text-error-750	' />
          </div>
        </div>
        {showSaveCardOption ? (
          <div className='fui-border fui-border-navy-300 fui-p-2 md:fui-p-4'>
            <Checkbox
              label={__T('Save card for future orders (credit cards only)')}
              labelClass='fui-underline fui-font-normal fui-text-navy-500'
              labelParentClass={'fui-gap-7px'}
              onChange={onChangeSavedCard}
              name='saved-card'
              defaultChecked={saveCard}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
