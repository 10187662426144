import cx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import IconLogoX from '@flowardco/fui-icons/src/IconLogoX';
import { redirectToRoute } from '@flowardco/flib-helpers';

export const Header = () => {
  const { lang = 'en', paymentToken = '' } = useParams();
  const language = lang.toLowerCase() === 'ar' ? 'ar' : 'en';
  const location = useLocation();
  const onLangugeSwitch = (e: any, inArabic: boolean) => {
    e.preventDefault();
    const currentUrl = location.pathname.split(
      `/${inArabic ? 'ar' : 'en'}/`
    )?.[1];
    let locationSearch = location.search;
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');

    if (query) {
      searchParams.set('q', decodeURI(query));
      locationSearch = `?${searchParams.toString()}`;
    }

    const newUrl = `/${inArabic ? 'en' : 'ar'}/`.concat(
      currentUrl,
      locationSearch
    );
    return redirectToRoute(newUrl);
  };
  const showLanguageSwitch = lang && paymentToken;
  return (
    <header className='fui-sticky fui-top-0 fui-z-1000 fui-mb-4 fui-flex fui-w-full fui-flex-1 fui-items-center fui-justify-between fui-bg-white fui-p-4'>
      <div className='fui-w-6' />
      <div className='fui-w-28'>
        <IconLogoX className='fui-block fui-w-full fui-text-navy-550' />
      </div>

      {showLanguageSwitch ? (
        <button
          type='button'
          className={cx(
            'fui-inline-block fui-cursor-pointer fui-text-md ltr:fui-float-left',
            language === 'ar' ? 'fui-font-english' : 'fui-font-arabic'
          )}
          onClick={(e) => onLangugeSwitch(e, language === 'ar')}
        >
          {language === 'ar' ? 'English' : 'العربية'}
        </button>
      ) : (
        <div />
      )}
    </header>
  );
};
