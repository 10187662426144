import { useLocalApp } from '@/hooks/useLocalApp';
import { UserHero } from '@/components/UserHero';
import { OrderCartList } from '@/components/OrderCartList';
import { DeliveryAddressCard } from '@/components/DeliveryAddressCard';
import { DeliveryDateCard } from '@/components/DeliveryDateCard';
import { CardMessageCard } from '@/components/CardMessageCard';
import {
  PaymentOptionsList,
  type PaymentDataProps,
} from '@/components/PaymentOptionsList';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { SenderNotes } from '@/components/SenderNotes';
import { OrderTotalSummary } from '@/components/OrderTotalSummary';
import { type FormEvent, useState, useEffect, Fragment } from 'react';
import { Button } from '@flowardco/ui/Button';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { addToast, formatPrice } from '@flowardco/flib-util';
import { COUNTRY_CODES } from '@flowardco/app-shared/lib/constants';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { OrderStatusEnum, PaymentMethodsEnum } from '@flowardco/flib-models';
import { Navigate, useParams } from 'react-router-dom';
import { placeOrder } from '@/api/order.service';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import IconInfoCircle from '@flowardco/fui-icons/src/IconInfoCircle';
import { Snackbar } from '@flowardco/fui-snackbar';

const SecurePaymentPage = () => {
  const { appState, getInitialOrderData } = useLocalApp();
  const { __T, __TK } = useLocalTranslator();
  const { lang = 'en', paymentToken } = useParams();
  const language = lang?.toLowerCase() === 'ar' ? 'ar' : 'en';
  const {
    orderData,
    orderAddressData,
    orderCardData,
    orderCartItemsData = [],
    paymentListItems = [],
    isAppLoading,
    refetchTime,
  } = appState;
  const paymentDataInit: PaymentDataProps = {
    paidFromWallet: false,
    saveCard: false,
    noteForFloward: '',
    paymentType: undefined,
    paymentVendor: 0,
    autoRenew: false,
    callbackUrl: `${window.location.origin}/${lang}/checkout-confirmation/${paymentToken}`,
    // below one is used for local testing
    // callbackUrl: `https://secure.staging.floward.io/${lang}/checkout-confirmation/${paymentToken}`,
    cardID: -1,
    tapToken: '',
    cardDetails: '',
    CardBin: '',
    title: '',
  };
  const [paymentDataState, setPaymentDataState] =
    useState<PaymentDataProps>(paymentDataInit);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const submitCard = async () => {
    if (lang && orderData?.id && window?.Frames?.isCardValid()) {
      setIsProcessing(true);
      try {
        const res = await window.Frames.submitCard();
        const sentData = {
          ...paymentDataState,
          cardDetails: res,
          CardBin: res?.bin,
          tapToken: res?.token,
        };
        const submitRes = await placeOrder({
          lang,
          orderId: orderData?.id,
          paymentData: sentData,
        });
        if (submitRes?.order?.id) {
          paymentSuccess(submitRes);
        }
        setIsProcessing(false);
      } catch (error: any) {
        setIsProcessing(false);
        errorLoggerV2('SecurePayment::submitCard', error, {
          metaData: { error, paymentDataState },
        });
      }
    } else {
      // error card
      addToast(__T('Invalid card details'), 'error');
    }
  };

  const proceedToReceipt = async () => {
    const isCreditCardPayment =
      selectedPaymentMethod?.isCredit &&
      (selectedPaymentMethod?.id === PaymentMethodsEnum.CreditCard ||
        selectedPaymentMethod?.id === PaymentMethodsEnum.Mada);
    if (isCreditCardPayment) {
      submitCard();
      return;
    }
    completeCheckout();
  };

  const paymentSuccess = (res: any) => {
    if (!res.requiredRedirect) {
      window.location.href = `/${lang}/checkout-confirmation/${paymentToken}?r=${res?.order?.id}&st=2`;
    } else {
      window.location.href =
        res?.redirectUrl ||
        `/${lang}/checkout-confirmation/${paymentToken}?r=${res?.order?.id}&st=2`;
    }
  };

  const completeCheckout = async () => {
    if (lang && orderData?.id && paymentDataState?.title) {
      try {
        const res = await placeOrder({
          lang,
          orderId: orderData?.id,
          paymentData: paymentDataState,
        });
        if (res?.order?.id) {
          paymentSuccess(res);
        }
      } catch (error: any) {
        errorLoggerV2('SecurePayment::completeCheckout', error, {
          metaData: { error, paymentDataState },
        });
      }
    }
  };

  const onPaymentSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (selectedPaymentMethod?.title) {
      proceedToReceipt();
    }
  };

  useEffect(() => {
    if (paymentToken && language && refetchTime) {
      getInitialOrderData(paymentToken, language);
    }
  }, [paymentToken, language, refetchTime]);
  if (isAppLoading) {
    return (
      <Fragment>
        <FullPageLoader />
      </Fragment>
    );
  }

  if (orderData?.status === OrderStatusEnum.Confirmed) {
    return (
      <Navigate
        to={`/${lang}/checkout-confirmation/${paymentToken}?r=${orderData?.id}&st=2`}
      />
    );
  }
  // only used here to display the error message
  const orderStatusString = {
    0: 'Not Confirmed',
    1: 'Delivered',
    2: 'Confirmed',
    3: 'Canceled',
    4: 'Under Delivery',
    5: 'Refound',
    6: 'Pending Cancellation',
    7: 'Pre Order Pending',
  };

  if (orderData?.status !== OrderStatusEnum.Not_Confirmed) {
    return (
      <Fragment>
        <div className='fui-flex fui-w-full fui-flex-col fui-items-center fui-justify-center fui-px-4 fui-py-2 fui-align-middle'>
          <IconInfoCircle className='fui-h-32 fui-w-32 fui-text-theme-navy-900' />
          <h1 className='fui-py-2 fui-pt-4 fui-text-center fui-text-lg fui-font-medium'>
            {__T("We can't process your order. Please try again")}
          </h1>
          <div className='fui-py-4'>
            <Snackbar
              noteTextClass='fui-text-center !fui-text-base !fui-font-medium'
              noteText={__T(
                'Order Status is {orderstatus}. Please contact our customer service.',
                { orderstatus: orderStatusString?.[orderData?.status || 0] }
              )}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  if (!orderData?.id) {
    return (
      <Fragment>
        <div className='fui-flex fui-w-full fui-flex-col fui-items-center fui-justify-center fui-px-4 fui-py-2 fui-align-middle'>
          <IconInfoCircle className='fui-h-32 fui-w-32 fui-text-theme-navy-900' />
          <h1 className='fui-py-2 fui-pt-4 fui-text-center fui-text-lg fui-font-medium'>
            {__T('No Order found')}
          </h1>
        </div>
      </Fragment>
    );
  }

  return (
    <div className='fui-px-2 fui-pb-24 fui-pt-4 md:fui-pb-2'>
      <AppErrorBoundary>
        <UserHero orderData={orderData} />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <OrderCartList data={orderCartItemsData} orderData={orderData} />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <DeliveryAddressCard orderAddressData={orderAddressData} />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <DeliveryDateCard />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <CardMessageCard orderCardData={orderCardData} />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <PaymentOptionsList
          onPaymentSelect={setSelectedPaymentMethod}
          paymentList={paymentListItems}
          orderData={orderData}
          isAppLoading={isAppLoading}
          paymentDataState={paymentDataState}
          setPaymentDataState={setPaymentDataState}
          onPaymentSubmit={onPaymentSubmit}
        />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <SenderNotes
          paymentDataState={paymentDataState}
          setPaymentDataState={setPaymentDataState}
        />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <OrderTotalSummary orderData={orderData} />
      </AppErrorBoundary>
      <div className='fui-fixed fui-bottom-0 fui-left-0 fui-right-0 fui-top-auto fui-z-10 fui-flex fui-w-full fui-items-center fui-justify-center fui-bg-white/30 fui-px-3 fui-py-4 fui-align-middle fui-shadow-up fui-backdrop-blur-sm md:fui-sticky md:fui-mt-6 md:fui-shadow-none'>
        <Button
          isProcessing={isProcessing}
          label={__T('Pay {value}', {
            value: formatPrice(
              orderData?.summary?.grandTotal || 0,
              __TK(
                orderData?.operation?.currency,
                orderData?.operation?.currencyAr
              ),
              COUNTRY_CODES[orderData?.operation?.countryId || 2]
            ),
          })}
          type='submit'
          className='fui-w-full'
          form='checkoutPaymentForm'
          id='placeOrderButton'
          disabled={!selectedPaymentMethod?.title || isProcessing}
        />
      </div>
    </div>
  );
};

export default SecurePaymentPage;
