import React, { useState } from 'react';
import cx from 'clsx';

export interface PictureProps {
  onClick?(event: React.MouseEvent, href?: string): void;
  containerClassName?: string;
  imageClass?: string;
  href?: string;
  mobileSrc: string;
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  target?: '_self' | '_blank';
  anchorProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
  imgTestId?: string;
  loading?: 'lazy' | 'eager';
  imgContainerClassName?: string;
  showSkeleton?: boolean;
}

export const Picture: React.FC<PictureProps> = ({
  mobileSrc,
  src,
  containerClassName = '',
  imageClass = '',
  href = '',
  alt = '',
  width = '100%',
  height = 'auto',
  target = '_self',
  onClick = null,
  anchorProps,
  imgTestId = 'TestId__PictureImage',
  loading = 'lazy',
  imgContainerClassName = '',
  showSkeleton = true,
}) => {
  const [isSkeletonVisible, setIsSkeletonVisible] = useState(showSkeleton);
  const onLoad = () => {
    if (showSkeleton) {
      setIsSkeletonVisible(false);
    }
  };
  const onError = (e: any) => {
    if (showSkeleton) {
      setIsSkeletonVisible(false);
      e.currentTarget.visibility = 'hidden';
    }
  };

  const childComponent = () => (
    <div
      data-testid='TestId__ImageSkeletonContainer'
      className={cx(imgContainerClassName)}
    >
      <picture
        className={cx(
          'fui-z-[1] fui-block fui-w-full fui-overflow-hidden',
          isSkeletonVisible ? 'skeleton' : '',
          containerClassName
        )}
        data-testid='TestId__Picture'
      >
        <source media='screen and (max-width: 767px)' srcSet={mobileSrc} />
        <source media='screen and (min-width: 768px)' srcSet={src} />
        <img
          loading={loading}
          height={height}
          width={width}
          className={cx('fui-relative fui-z-[3]', imageClass)}
          src={src}
          alt={alt}
          data-testid={imgTestId}
          onLoad={onLoad}
          onError={(e) => onError(e)}
        />
      </picture>
    </div>
  );

  if (href) {
    return (
      <a
        onClick={(e) => {
          if (onClick) {
            onClick(e, href);
          }
        }}
        href={href}
        target={target}
        className={cx('fui-block', containerClassName)}
        data-testid='TestId__PictureAnchor'
        {...anchorProps}
      >
        {childComponent()}
      </a>
    );
  }
  return childComponent();
};
