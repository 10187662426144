import React, { useState } from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconCalendarNew from '@flowardco/fui-icons/src/IconCalendarNew';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { DeliverySlotsModal } from './DeliverySlotsModal';
import { useLocalApp } from '@/hooks/useLocalApp';

export const DeliveryDateCard = () => {
  const { __T } = useLocalTranslator();
  const {
    appState: { orderData },
  } = useLocalApp();
  const [showSlotsModal, setShowSlotsModal] = useState<boolean>(false);
  const deliveryData = orderData?.deliveryDate?.split('T')?.[0];
  const toggleModal = () => {
    setShowSlotsModal((prev) => !prev);
  };

  return (
    <div className='fui-block fui-pb-2 fui-pt-3'>
      <div className='fui-flex fui-w-full fui-items-center fui-justify-between fui-px-1 fui-py-3 fui-align-middle'>
        <span>
          <IconHeading
            title={__T('Delivery Time')}
            icon={<IconCalendarNew />}
          />
        </span>
        <span>
          <button
            className='fui-text-sm fui-font-medium fui-text-theme-navy-900'
            type='button'
            onClick={toggleModal}
          >
            {__T('Change')}
          </button>
        </span>
      </div>
      <div className='fui-dir-ltr fui-rounded-md fui-border fui-border-gray-250 fui-p-3 ltr:fui-text-left rtl:fui-text-right'>
        {`${deliveryData} ${orderData?.deliveryTimeName}`}
      </div>
      {showSlotsModal && orderData ? (
        <DeliverySlotsModal onCloseModal={toggleModal} orderData={orderData} />
      ) : null}
    </div>
  );
};
