import React from 'react';
import { IconHeading } from '@flowardco/fui-icon-heading';
import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import { AddressCard } from '@flowardco/fui-address-card';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

export const DeliveryAddressCard = ({
  orderAddressData = {},
}: {
  orderAddressData: any;
}) => {
  const { __T } = useLocalTranslator();
  return (
    <div className='fui-block fui-pt-3'>
      <div className='fui-block fui-px-1 fui-py-3'>
        <IconHeading title={__T('Deliver To')} icon={<IconMapMarker />} />
      </div>
      <div className='fui-rounded-md fui-border fui-border-gray-250'>
        <AddressCard
          iconUrl={null}
          postalCode={orderAddressData?.postalCode}
          name={orderAddressData?.addressLineOne}
          addressLine={orderAddressData?.addressLineTwo}
          recipientName={orderAddressData?.recipientName}
          recipientPhone={orderAddressData?.recipientPhone}
          isEditMode={false}
          nameLabel={__T('Name')}
          phoneLabel={__T('Phone')}
          className='!fui-mb-0 fui-rounded-md !fui-border-0'
        />
      </div>
    </div>
  );
};
