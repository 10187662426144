import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconInfoCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    ref={ref}
    {...props}
  >
    <path
      d='M2.5 10C2.5 14.1416 5.85757 17.5 10 17.5C14.1424 17.5 17.5 14.1416 17.5 10C17.5 5.85757 14.1424 2.5 10 2.5C5.85757 2.5 2.5 5.85757 2.5 10Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0048 13.0777V9.49466M10 6.96188V6.90918'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconInfoCircle);
export default ForwardRef;
