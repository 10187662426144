import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Suspense } from 'react';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import { Header } from '@/components/Header';

const LayoutPage = () => {
  const { lang = 'en' } = useParams();
  const language = lang.toLowerCase() === 'ar' ? 'ar' : 'en';
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  return (
    <>
      <Helmet>
        <html lang={language || 'en'} dir={dir} />
      </Helmet>
      <Suspense fallback={<FullPageLoader />}>
        <div className='fui-justify-startfui-bg-gray-60 fui-mx-auto fui-flex fui-max-w-full fui-flex-col fui-py-2 md:fui-max-w-[32rem]'>
          <main className='fui-flex fui-flex-1 fui-flex-col fui-bg-white'>
            <Header />
            <div className='fui-block fui-overflow-x-hidden fui-px-2'>
              <Outlet />
            </div>
          </main>
        </div>
      </Suspense>
    </>
  );
};

export default LayoutPage;
