import React, { Fragment, useEffect, useState } from 'react';
import type { PaymentMethodProp } from './PaymentMethod';
import { PaymentMethod } from './PaymentMethod';

export interface PaymentListProps {
  onChange(item: PaymentMethodProp): void;
  onDelete?(item: PaymentMethodProp): void;
  className?: string;
  paymentList: PaymentMethodProp[];
  children?: React.ReactNode;
  __T: any;
}

export const PaymentList: React.FC<PaymentListProps> = ({
  className = '',
  paymentList = [],
  onChange,
  onDelete,
  children = null,
  __T,
}) => {
  const defaultPaymentMethod =
    paymentList?.find((i) => i.defaultMethod && !i.isExpired) ||
    paymentList?.find((i) => !i.isExpired);
  const [selectedPayment, setSelectedPayment] = useState(defaultPaymentMethod);

  useEffect(() => {
    if (onChange && defaultPaymentMethod) {
      onChange(defaultPaymentMethod);
    }
  }, []);

  const onHandleChange = (item: PaymentMethodProp) => {
    setSelectedPayment(item);
    if (onChange) {
      onChange(item);
    }
  };

  const onHandleDelete = (item: PaymentMethodProp) => {
    if (onDelete) {
      onDelete(item);
    }
  };
  return (
    <div className={className} data-testid='TestId__PaymentList'>
      {children}
      {paymentList?.length > 0 &&
        paymentList?.map((item: any, idx: number) => {
          return (
            <Fragment
              key={`${item.title}-${item.cardID}-${item.Id}-${
                item?.savedCardId || idx
              }`}
            >
              <PaymentMethod
                paymentMethod={item}
                onChange={onHandleChange}
                onDelete={onHandleDelete}
                selectedPayment={selectedPayment}
                __T={__T}
              />
              {item?.cardForm}
            </Fragment>
          );
        })}
    </div>
  );
};
