import React from 'react';
import cx from 'clsx';

export interface QuantityCounterProps {
  wrapperClasses?: string;
  disabled?: boolean;
  name: string;
  onChange?: (e: number) => void;
  maxValue?: number;
  minValue?: number;
  value?: number;
  onIncrement?: (newVal: number) => void;
  onDecrement?: (newVal: number) => void;
}

export const QuantityCounter: React.FC<QuantityCounterProps> = ({
  wrapperClasses = '',
  value = 1,
  disabled = false,
  name,
  onIncrement,
  onDecrement,
  onChange = () => null,
  maxValue = 99,
  minValue = 1,
}) => {
  const handleIncrement = () => {
    if (value < maxValue) {
      const newValue = value + 1;
      onChange(value + 1);
      if (onIncrement) {
        onIncrement(newValue);
      }
    }
  };

  const handleDecrement = () => {
    if (value > minValue) {
      const newValue = value - 1;
      onChange(newValue);
      if (onDecrement) {
        onDecrement(newValue);
      }
    }
  };

  return (
    <div
      className={cx(
        'fui-align-center fui-flex fui-justify-around fui-rounded-lg fui-border fui-border-solid fui-border-theme-navy-900 fui-p-2px',
        wrapperClasses
      )}
      data-testid='TestId__QuantityCounter'
    >
      <button
        className='fui-flex fui-h-4 fui-w-4 fui-cursor-pointer fui-items-center fui-justify-center fui-self-center fui-text-center fui-font-medium'
        onClick={handleDecrement}
        data-testid='TestId__DecrementBtn'
        disabled={disabled}
      >
        -
      </button>
      <input
        className={cx(
          'fui-w-1/2 fui-bg-white fui-text-center fui-text-base fui-text-theme-navy-950 focus:fui-outline-0'
        )}
        value={value}
        data-testid='TestId__counterInput'
        name={name}
        readOnly
      />
      <button
        className='fui-flex fui-h-4 fui-w-4 fui-cursor-pointer fui-items-center fui-justify-center fui-self-center fui-text-center fui-font-medium'
        onClick={handleIncrement}
        data-testid='TestId__IncrementBtn'
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};
