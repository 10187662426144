import React from 'react';

export const LoadingSkeleton = () => {
  return (
    <div className='fui-flex fui-w-full fui-flex-nowrap fui-justify-start fui-overflow-hidden'>
      <div className='fui-animation-delay-75 fui-block fui-w-1/4 fui-animate-pulse fui-p-1'>
        <div className='rounded-full fui-mb-2 fui-h-12 fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
      <div className='fui-animation-delay-75 fui-block fui-w-1/4 fui-animate-pulse fui-p-1'>
        <div className='rounded-full fui-mb-2 fui-h-12 fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
      <div className='fui-animation-delay-75 fui-block fui-w-1/4 fui-animate-pulse fui-p-1'>
        <div className='rounded-full fui-mb-2 fui-h-12 fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
      <div className='fui-animation-delay-75 fui-block fui-w-1/4 fui-animate-pulse fui-p-1'>
        <div className='rounded-full fui-mb-2 fui-h-12 fui-w-full fui-bg-gray-15/20'></div>
        <div className='rounded-full fui-mb-2.5 fui-h-2 fui-max-w-[360px] fui-bg-gray-15/20'></div>
      </div>
    </div>
  );
};
