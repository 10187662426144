import React, { createContext, useReducer } from 'react';
import type { SecureCheckoutOrderDataModel } from '@flowardco/flib-models';
export interface LocalAppContextProps {
  isAppLoading: boolean;
  isAppError: boolean;
  lang: string;
  orderData?: SecureCheckoutOrderDataModel;
  orderAddressData?: any;
  orderDeliveryDates?: any;
  orderCartItemsData?: any;
  orderCardData?: any;
  orderToken?: string;
  paymentToken?: string;
  refetchTime: number;
  paymentListItems: any[];
}

export interface AppStateActionProps {
  type: string;
  payload?: any;
}

export const defaultLocalAppContext: LocalAppContextProps = {
  isAppLoading: true,
  isAppError: false,
  lang: 'en',
  orderData: undefined,
  orderAddressData: undefined,
  orderDeliveryDates: undefined,
  orderCartItemsData: undefined,
  orderCardData: undefined,
  orderToken: undefined,
  paymentListItems: [],
  paymentToken: undefined,
  refetchTime: Date.now(),
};

export const SET_APP_ERROR = 'SET_APP_ERROR';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_ORDER_TOKEN = 'SET_ORDER_TOKEN';
export const SET_REFETCH_ORDER = 'SET_REFETCH_ORDER';
export const SET_ORDER_ADDRESS_DATA = 'SET_ORDER_ADDRESS_DATA';
export const SET_ORDER_CARTITEMS_DATA = 'SET_ORDER_CARTITEMS_DATA';
export const SET_ORDER_CARD_DATA = 'SET_ORDER_CARD_DATA';
export const SET_ORDER_PAYMENT_LIST_DATA = 'SET_ORDER_PAYMENT_LIST_DATA';
export const SET_ORDER_DELIVERY_DATES_DATA = 'SET_ORDER_DELIVERY_DATES_DATA';

export const LocalAppContext = createContext<any>(defaultLocalAppContext);

export const appReducer = (
  state: LocalAppContextProps,
  action: AppStateActionProps
): any => {
  switch (action.type) {
    case SET_ORDER_TOKEN:
      return {
        ...state,
        lang: action.payload.lang,
        paymentToken: action.payload.paymentToken,
        orderToken: action.payload.orderToken,
      };
    case SET_REFETCH_ORDER:
      return {
        ...state,
        refetchTime: action.payload,
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.payload,
      };
    case SET_ORDER_ADDRESS_DATA:
      return {
        ...state,
        orderAddressData: action.payload,
      };
    case SET_ORDER_CARTITEMS_DATA:
      return {
        ...state,
        orderCartItemsData: action.payload,
      };
    case SET_ORDER_CARD_DATA:
      return {
        ...state,
        orderCardData: action.payload,
      };
    case SET_ORDER_PAYMENT_LIST_DATA:
      return {
        ...state,
        paymentListItems: action.payload,
      };
    case SET_ORDER_DELIVERY_DATES_DATA:
      return {
        ...state,
        orderDeliveryDates: action.payload,
      };
    case SET_APP_LOADING:
      return { ...state, isAppLoading: action.payload };
    case SET_APP_ERROR:
      return { ...state, isAppError: action.payload };
    default:
      return state;
  }
};

export const LocalAppContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ ...props }) => {
  const [appState, appDispatch] = useReducer(appReducer, {
    ...defaultLocalAppContext,
  });

  const appConfigs = {
    appState,
    appDispatch,
  };
  return (
    <LocalAppContext.Provider value={appConfigs}>
      {props.children}
    </LocalAppContext.Provider>
  );
};
